import React from "react";
import {Box,Sidebar,Image,Button,Text,Select} from "grommet";
import {Home,UserWorker,List,MapLocation,Configure,Analytics,Notification} from "grommet-icons";
import { useHistory } from "react-router-dom";
import firebase from '../firebase';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

/**
 * Componente che aggiunge la SideBar nei componenti che non si muovono su App.js e nei quali vengono passati dati
 * tramite props.match
 */
const SideBar=(props)=>{

    let history=useHistory();
    
    const notify = firebase.firestore().collection('Notifiche');
    const [notifiche, setNotifiche] = React.useState([]);
    const [smallSideBar,setSmallSideBar]=React.useState(false);
    const [dipendenteLoggato,setDipendenteLoggato]=React.useState(null);
    const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyDrje9pCvY8yV0DJ8fz3MsI05pYsS4Tafc:[DEFAULT]"));

    function getNotifiche() {
        notify.where("email", "==", userLogged.email).onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setNotifiche(items);
        });
    }

    function getDipendenteLoggato(){
      firebase.firestore().collection('Dipendenti').where("email","==",userLogged.email).onSnapshot((querySnapshot)=>{
        let dipendenteLoggato;
        querySnapshot.forEach((doc)=>{
          dipendenteLoggato=doc.data();
        })
        setDipendenteLoggato(dipendenteLoggato);
      })
    }

    const [ruolo,setRuolo]=React.useState();

    /**
     * Per ridurre il lag della sidebar leggo il ruolo da session storage
     */
    function getRuolo(){
        if(sessionStorage.getItem('Role')!==undefined){
            setRuolo(sessionStorage.getItem('Role'));
        }
    }


    React.useEffect(()=>{
        getRuolo();
        getNotifiche();
        getDipendenteLoggato();
        if(sessionStorage.getItem("smallSidebar")!==null){
          if(sessionStorage.getItem("smallSidebar")==="true"){
            setSmallSideBar(true);
          }else{
            setSmallSideBar(false)
          }
        }
    },[])

    return(   
      <Sidebar
      height="97vh"
      width= {!smallSideBar?"220px":"100px"}
      responsive={true}
      background="#FFFFFF"
      header={ 
     
     <Box align="center" >
         
         {
          !smallSideBar?
          <>
          <Box height="small" pad="small">
            <Image fit="contain" src="../images/Logo1.png" />
          </Box>
          </>
          :
          null
         }
         <Box align="center" direction="column" gap="7px" >
          <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:props.page=="Home"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() =>{history.push({
                        pathname: '/home',
                        page: "Home"
                    })}}>
              <Box pad="10px" direction="row" align="center" gap="medium">
                  <Home color={props.page!="Home"?'black':'#FFFFFF'} />
                  {
                    !smallSideBar?
                    <Text size="xsmall" color={props.page!="Home"?'black':'#FFFFFF'}>Home</Text>
                    :
                    null
                  }
               
              </Box>
          </Button>
          {props.ruolo !== undefined ?
              ( props.ruolo == 'Admin') ||  props.ruoloo == 'Admin_Cantiere' && dipendenteLoggato && dipendenteLoggato.ModificaAnagrafiche ||  props.ruolo == 'Admin_Zona' && dipendenteLoggato && dipendenteLoggato.ModificaAnagrafiche ?
          <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:props.page=="Anagrafiche"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() => {history.push({
            pathname: '/home',
            page: "Anagrafiche"
          })}}>
              <Box pad="10px" direction="row" align="center" gap="medium">
                  <UserWorker color={props.page!="Anagrafiche"?'black':'#FFFFFF'} />
                  {
                    !smallSideBar?
                  <Text size="xsmall" color={props.page!="Anagrafiche"?'black':'#FFFFFF'}>Anagrafiche</Text>
                  :
                  null
                  }
              </Box>
          </Button>
          :
          null
          :
          null
          }
          {props.ruolo !== undefined ?
              props.ruolo == 'Admin' ||  props.ruolo == 'Admin_Cantiere' ||  props.ruolo == 'Admin_Zona' ?
          <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:props.page=="Attività"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{  history.push({
            pathname: '/home',
            page: "Attività"
        })}}> 
              <Box pad="10px" direction="row" align="center" gap="medium">
                  <List color={props.page!="Attività"?'black':'#FFFFFF'}/>
                  {
                    !smallSideBar?
                    <Text size="xsmall" color={props.page!="Attività"?'black':'#FFFFFF'}>Attività</Text>
                    :
                    null
                  }
              </Box>
          </Button>
          :
          null
          :
          null
          }
          {props.ruolo !== undefined ?
                  props.ruolo == 'Admin' ||  props.ruolo == 'Admin_Cantiere' ||  props.ruolo == 'Admin_Zona' ?
          
          <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:props.page=="Geo ref."?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{ history.push({
            pathname: '/home',
            page: "Geo ref."
         })}}>
              <Box pad="10px" direction="row" align="center" gap="medium">
                  <MapLocation color={props.page!="Geo ref."?'black':'#FFFFFF'} />
                  {
                    !smallSideBar?
                      <Text size="xsmall" color={props.page!="Geo ref."?'black':'#FFFFFF'}>Mappa</Text>
                    :
                    null
                  }
              </Box>
          </Button>
          :
          null
          :
          null
          }
          {
          props.ruolo !== undefined ?
          props.ruolo == 'Admin' ||  props.ruolo == 'Admin_Cantiere' ||  props.ruolo == 'Admin_Zona' ||  props.ruolo == 'Meccanico' ?
          <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:props.page=="Manutenzione"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{  history.push({
            pathname: '/home',
            page: "Manutenzione"
          })}}>
              <Box pad="10px" direction="row" align="center" gap="medium">
                  <Configure color={props.page!="Manutenzione"?'black':'#FFFFFF'} />
                  {
                    !smallSideBar?
                    <Text size="xsmall"  color={props.page!="Manutenzione"?'black':'#FFFFFF'}>Macchinari</Text>
                    :
                    null
                  }
                  
              </Box>
          </Button>
          :
          null
          :
          null
          }
          {
          props.ruolo !== undefined ?
          props.ruolo == 'Admin' ||  props.ruolo == 'Admin_Cantiere' ||  props.ruolo == 'Admin_Zona'  ?
          
          <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:props.page=="Analitiche"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{   history.push({
            pathname: '/home',
            page: "Analitiche"
          })}}>
              <Box pad="10px" direction="row" align="center" gap="medium">
                  <Analytics color={props.page!="Analitiche"?'black':'#FFFFFF'}/>
                  {
                    !smallSideBar?
                    <Text size="xsmall" color={props.page!="Analitiche"?'black':'#FFFFFF'}>Analitiche</Text>
                    :
                    null
                  }
                 
              </Box>
          </Button>
          :
          null
          :
          null
          }
          <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:props.page=="Notifiche"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{
              history.push({
                pathname: '/home',
                page: "Notifiche"
            })
          }}
              badge={{
                background: notifiche.length == 0 ? "none" : "status-critical",
                value: notifiche.length == 0 ? false : notifiche.length,
                max: 9,
                size: 'medium'
              }}
            >
              <Box pad="small" direction="row" align="center" gap="medium">
                  <Notification color={props.page!="Notifiche"?'black':'#FFFFFF'} />
                  {
                    !smallSideBar?
                    <Text size="xsmall" color={props.page!="Notifiche"?'black':'#FFFFFF'} >Notifiche</Text>
                    :
                    null
                  }
                
              </Box>
          </Button>
      </Box>    
      </Box>
      
      }   
      footer=
                {!smallSideBar?
                  <Button style={{width:"55px",background:'white',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() => {setSmallSideBar(true);sessionStorage.setItem("smallSidebar",true)}}>
                    <Box pad="small" direction="row" align="center" gap="medium">
                        <ArrowCircleLeftIcon color='success' fontSize="large"/>
                    </Box>
                 </Button>
                :
              <Button style={{width:"55px",background:'white',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() => {setSmallSideBar(false);sessionStorage.setItem("smallSidebar",false)}}>
                    <Box pad="small" direction="row" align="center" gap="medium">                     
                        <ArrowCircleRightIcon color='success' fontSize="large"/>
                    </Box>
                 </Button>
                } 
      >
      
  </Sidebar>
    )
}

export default SideBar;