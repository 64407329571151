import React, { useState, useEffect } from "react";
import { Box, Menu, Grid, Grommet,Image, Text, Table, TableHeader, TableBody, Select, TableRow, TableCell, Clock, TextInput, Button, Calendar, Anchor, CheckBoxGroup, WorldMap, DataChart, DataTable, Meter, Heading, Collapsible, Layer, ResponsiveContext, DropButton, Notification as NotificationApp ,Tabs,Tab} from 'grommet';
import { FormClose, Notification, Home, CircleInformation, Add, UserWorker, MapLocation, Analytics, Configure, Task,LinkPrevious } from "grommet-icons";
import Notifications from "./Notifications";
import AddAtt from "./AddAtt";
import AnagraficheAtt from "./AnagraficheAtt";
import AnagraficheAtt2 from "./AnagraficheAtt2";
import firebase from '../firebase';
import  { Redirect ,useHistory} from 'react-router-dom';
import SideBar from "./SideBar";
import SideBarMobile from "./SideBarMobile";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


const theme = {
  global: {
    colors: {
      brand: '#006837',
      'accent-1': 'white',
      'focus': '#006837',
      "my-text-color": "",
      background: '#0350281A',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
  },
};

const App = () => {
  const att = firebase.firestore().collection('Attivita');
  const role = firebase.firestore().collection('Ruoli');
  const [attivita, setAttivita] = useState([]);
  const [showSideBarMob,setShowSideBarMob]=React.useState(false);
  const [landScapeError,setLandScapeError]=React.useState(false);
  const [ruoli, setRuoli] = useState([]);
  

  /**
   * Funzione che in base al cantiere selezionato rileva tutte le attività presenti a sistema
   */
  function getAttivita() {
        att.where("Cantiere","==",sessionStorage.getItem("Cantiere")).orderBy('Data').onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setAttivita(items);
        })
  }

  /**
   * Funzione che legge il ruolo dell'utente loggato
   */
  function getRuoli() {
    role.where('Utente', '==', userLogged.email).onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push( { ... doc.data(), id: doc.id } );
      });
      setRuoli(items);     
    });
 }

  useEffect(() => {
    getAttivita();
    getRuoli();
  }, []);

  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  
  
    /**
     * Funzione che permette di sapere le dimensioni aggiornate dello schermo
     */
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }
  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  /**
   * Controllo in base a width e height l'orientamento dello schermo.
   * Per essere respondive blocco la web app in LandScape.
   */
  React.useEffect(()=>{
    if(width<1100){
      if(width<height){
        setLandScapeError(true);
      }else{
        setLandScapeError(false);
      }    
    }
  },[width,height])

  if (sessionStorage.getItem("firebase:authUser:AIzaSyDrje9pCvY8yV0DJ8fz3MsI05pYsS4Tafc:[DEFAULT]") == null) {
    return <Redirect to='/'  />
  }

  const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyDrje9pCvY8yV0DJ8fz3MsI05pYsS4Tafc:[DEFAULT]"));

  return (
    <Grommet theme={theme} themeMode="dark" className="font-link">
      <ResponsiveContext.Consumer>
        {size => (
        !landScapeError?
        <Box fill>
          <Box  direction={width>=1100?"row":"column"}>   
            {
              width>=1100?
              <Box flex="false"> 
                <SideBar  pathLogo="./images/Logo1.png" page="Attività" ruolo={(ruoli[0]!==undefined)?ruoli[0].Ruolo:null}/>    
              </Box>
              :
              <AppBar position="static" style={{ background: '#006837'}}>
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Taskonnect
                </Typography>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => {
                   if(!showSideBarMob){
                    setShowSideBarMob(true);
                   }else{
                     setShowSideBarMob(false);
                   }      
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            }
           {
             showSideBarMob?
             <SideBarMobile page="Attività" ruolo={(ruoli[0]!==undefined)?ruoli[0].Ruolo:null}/>
             :
            <Box overflow={{ horizontal: 'hidden' }} height="95vh" width="100vw">         
                    <Box  width="100%" align="center" pad="small">
                      <Tabs alignControls="start">
                        <Tab title="Aggiungi">
                          <Box overflow="auto"  width="95%" alignSelf="center" pad="small">
                            <AddAtt />                        
                          </Box>
                        </Tab>
                        <Tab title="Tabella">
                          <Box overflow="auto" pad="small">
                            <Table>
                              <TableHeader>
                                <TableRow>
                                  {/*<TableCell scope="col" border="bottom">
                                    Stato
                                  </TableCell>*/}
                                  <TableCell scope="col" border="bottom">
                                    Dipendente
                                  </TableCell>
                                  <TableCell scope="col" border="bottom">
                                    Macchinario
                                  </TableCell>
                                  <TableCell scope="col" border="bottom">
                                    Lavoro
                                  </TableCell>
                                  <TableCell scope="col" border="bottom">
                                    Area di Lavoro
                                  </TableCell>
                                  <TableCell scope="col" border="bottom">
                                    Prodotto
                                  </TableCell>
                                  <TableCell scope="col" border="bottom">
                                    Data Del Lavoro (Stimato)
                                  </TableCell>
                                  {/*<TableCell scope="col" border="bottom">
                                    Data Inizio Lavoro (Effettivo)
                                  </TableCell>
                                  <TableCell scope="col" border="bottom">
                                    Data Fine Lavoro (Effettivo)
                                  </TableCell>
                                  <TableCell scope="col" border="bottom">
                                    Messaggio
                                  </TableCell>
                                  <TableCell scope="col" border="bottom">
                                    Messaggio Lavoratore
                                  </TableCell>
                                  <TableCell scope="col" border="bottom">
                                    Durata Stimata (min)
                                  </TableCell>
                                  <TableCell scope="col" border="bottom">
                                    Durata Consuntivo (min)
                                  </TableCell>*/}
                                  <TableCell scope="col" border="bottom">
                                    Ordinaria/Straordinaria
                                  </TableCell>
                                </TableRow>
                              </TableHeader>
                              <TableBody>
                                {attivita.map(attivita => (
                                  <AnagraficheAtt2 ruolo={ruoli[0]!==undefined?ruoli[0].Ruolo:""} attivita={attivita}/>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Tab>
                        </Tabs>
                    </Box>
                   
            </Box>
          }
          </Box>
        </Box>
        :
        <Box fill gap="small">
        <AppBar position="static" style={{ background: '#006837'}}>
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Taskonnect
              </Typography>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                 if(!showSideBarMob){
                  setShowSideBarMob(true);
                 }else{
                   setShowSideBarMob(false);
                 }      
                }}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        <Box width="100%" height="100%" align="center" alignSelf="center" background="white">
          <Text margin="small" color="#04671E" size="large" weight="bold">GIRA LO SCHERMO PER UTILIZZARE TASKONNECT</Text>
        </Box>
        </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;
