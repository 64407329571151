import firebase from 'firebase';

// Add the Firebase products that you want to use
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/messaging';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDrje9pCvY8yV0DJ8fz3MsI05pYsS4Tafc",
  authDomain: "app-g-admin-test-env.firebaseapp.com",
  databaseURL: "https://app-g-admin-test-env-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "app-g-admin-test-env",
  storageBucket: "app-g-admin-test-env.appspot.com",
  messagingSenderId: "64398345695",
  appId: "1:64398345695:web:00dd3bc160d95f26c0b06a",
  measurementId: "G-X2JFLRE56N"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export default firebase;