import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

export const OreLame = (props) => (
  <Card
    sx={{ height: '100%' }}
    {...props}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="overline"
          >
            Ore lame nel range selezionato
          </Typography>
          <Typography
            color="textPrimary"
            variant="h4"
          >
            {props.oreLame!==undefined && !props.erroreOreLame?
            props.oreLame
          :
          props.erroreOreLame?
          "Sincronizzazione in corso"
          :
          ""} 
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: '#795548',
              height: 56,
              width: 56
            }}
          >
            <HourglassBottomIcon />
          </Avatar>
        </Grid>
      </Grid>
      <Box
        sx={{
          pt: 2,
          display: 'flex',
          alignItems: 'center'
        }}
      >
      </Box>
    </CardContent>
  </Card>
);
