import React, { useState, useEffect, useRef } from "react";
import { Box, Menu, Grid, Grommet, Text, Card, CardHeader, CardBody, Accordion, AccordionPanel, Table, TableHeader, TableBody, TableRow, Image, Select, CardFooter, TableCell, Clock, TextInput, Button, Anchor, CheckBoxGroup, WorldMap, DataChart, DataTable, Meter, Heading, Collapsible, Layer, ResponsiveContext, DropButton, Notification as NotificationApp,Tab,Tabs,DateInput } from 'grommet';
import { FormClose, Notification, Home, CircleInformation, Add, Favorite, ShareOption, LinkPrevious, UserWorker, MapLocation, Analytics, Task, Configure, Update,Clock as ClockIcon,Calendar,Download } from "grommet-icons";
import firebase from '../firebase';
import  { Redirect } from 'react-router-dom';
import AnagraficheManutenzioniEseguite from "./AnagraficheManutenzioniEseguite";
import AddEse from "./AddEse";
import { Divider } from "@material-ui/core";
import AddRil from "./AddRil"
import { GraficoTensione } from "./dashboardComponents/graficoTensione";
import {OreLame} from "./dashboardComponents/oreLame"
import { ConsumoMedio } from "./dashboardComponents/consumoMedio";
import { GraficoPressioneFiltro } from "./dashboardComponents/graficoPressFiltro";
import { StatoInterruttoreOlioIdr } from "./dashboardComponents/statoInterruttoreOlioId";
import { Box as BoxMaterial} from "@material-ui/core" ;
import {Grid as GridMaterial}from "@material-ui/core" ;
import { Container} from '@mui/material';
import { OreVeicolo} from './dashboardComponents/oreVeicolo.js'
import { GraficoGiriMotore } from './dashboardComponents/graficoGiriMotore';
import { LivelloCarburante } from './dashboardComponents/livelloCarburante';
import { GraficoTemperaturaOlio } from '../components/dashboardComponents/graficoTemperaturaOlio';
import moment from "moment";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import autoTable from 'jspdf-autotable';
import { CircularProgress } from '@mui/material';

const theme = {
  global: {
    colors: {
      brand: '#006837',
      'accent-1': 'white',
      'focus': '#006837',
      "my-text-color": "",
      background: '#0350281A',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
    menu:{
      icons:{
        color:'white',
        },
    },
  },
};


const App = (props) => {
  /*TK-24 Modulo per la gestione della manutenzione - START*/
  const [rilievi, setRilievi] = useState([]);
  const ril = firebase.firestore().collection('RilievoOre');
  const int = firebase.firestore().collection('CodiciInterventi');

  const ese = firebase.firestore().collection('ManutenzioniEseguite');
  const [eseguite,setEseguite] = useState([]);
  const [interventi,setInterventi] = useState([]);
  /*TK-24 Modulo per la gestione della manutenzione - END*/

  const mac = firebase.firestore().collection('Manutenzione');
  const [macchinari, setMacchinari] = useState([]);
  const [dropdownMenu,setDropDownMenu]=React.useState("");
  const [macchinarioSel,setMacchinarioSel]=React.useState(null);
  let eseguiteMacchinarioSel=[]
  const [mostraLayer,setMostraLayer]=React.useState(false);
  const [mostraLayerRilievo,setMostraLayerRilievo]=React.useState(false);
  const [selectedTab,setSelectedTab]=React.useState(0);
  const [macchinaSelOre,setMacchinaSelOre]=React.useState();
  const dataDashboard = firebase.firestore().collection('Dashboard');
  const macTable= firebase.firestore().collection('Manutenzione');
  const dipendenti=firebase.firestore().collection('Dipendenti');  
  const [elencoMacchinari,setElencoMacchinari]=React.useState([]);
  const [dropdownMacchinari,setDropDownMacchinari]=React.useState("")
  const [elencoDatiDashboard,setElencoDatiDashboard]=React.useState([]);
  const [oreVeicolo,setOreVeicolo]=React.useState();
  const [livelloCarburante,setLivelloCarburante]=React.useState();
  const [datasetGiriMotore,setDataSetGiriMotore]=React.useState([]);
  const [datasetTempOlio,setDataSetTempOlio]=React.useState([]);
  const [datasetTensione,setDataSetTensione]=React.useState([]);
  const [dataSetCoolTemp,setDataSetCoolTemp]=React.useState([]);
  const [dataSetPressioneFiltro,setDataSetPressioneFiltro]=React.useState([]);
  const [oreLame,setOreLame]=React.useState();
  const [consumoMedio,setConsumoMedio]=React.useState();
  const [dataInizio,setDataInizio]=React.useState(moment(new Date()).startOf('day').add(-7, 'd').format());
  const [dataFine,setDataFine]=React.useState(moment(new Date()).endOf('day').format());
  const [dizionarioMacchinari,setDizionarioMacchinari]=React.useState(new Map());
  const [statoInterruttoreOlioIdr,setStatoInterruttoreOlioIdr]=React.useState();
  const [erroreOreLame,setErroreOreLame]=React.useState(false);
  const [nessunDato,setNessunDato]=React.useState(false);
  const [tempoMacchinaIniziale,setTempoMacchinaIniziale]=React.useState(null);
  const [showLayerCaricamento,setShowLayerCaricamento]=React.useState(false);


  React.useEffect(()=>{
    for(var i=0;i<macchinari.length;i++){ //for classico per risparmiare iterazioni, nel momento in cui trova il macchinario rompe il ciclo
      if(macchinari[i].NomeMacchina==dropdownMenu){
        //Per segnalare che non c'è nessun dato per questa macchina
        if(macchinari[i].AffilareLame==="" && macchinari[i].CambioFiltroAria==="" && macchinari[i].CambioFiltroBenzina==="" && macchinari[i].CambioFiltroGasolio==="" && macchinari[i].CambioFiltroOlio==="" && macchinari[i].CambioFiltroOlioIdraulico==="" && macchinari[i].CambioFiltroOlioMotore==="" && macchinari[i].CambioFiltroOlioIdraulico==="" && macchinari[i].CambioOlioMotore===""){
          setNessunDato(true)
        }else{
          setNessunDato(false);
        }
        trovaOreIniziali(macchinari[i].Telaio);
        setMacchinarioSel(macchinari[i]);     
        break;
      }
    }
    if(sessionStorage.getItem("tab1")!==undefined && sessionStorage.getItem("tab1")!==null){
      sessionStorage.removeItem("tab1");

      setSelectedTab(1);
    }
  },[dropdownMenu])
  

  function trovaOreIniziali(telaio){
    macTable.where('Telaio', '==', telaio).onSnapshot((querySnapshot) => {
      var tempoMacchinaIniziale=null;
      querySnapshot.forEach((doc) => {
        var dati=doc.data();
        if(dati.TempoMacchinaIniziale!==""){  
         tempoMacchinaIniziale=dati.TempoMacchinaIniziale;
        } 
      });
      if(tempoMacchinaIniziale!==0 && tempoMacchinaIniziale!==""){
        setTempoMacchinaIniziale(tempoMacchinaIniziale);
      }else{
        setTempoMacchinaIniziale(null); //Necessario per farlo tornare a null
      }
     
    });
  }

  /**
   * Funzione che in base al ruolo e al cantiere/zona selezionato/a rileva i macchinari a sistema
   */
  function getManutenzioneMacchinari() {
     if(sessionStorage.getItem('Role') == 'Admin_Cantiere' && sessionStorage.getItem('Cantiere') !== null){
      console.log( sessionStorage.getItem('Cantiere'))
      macTable.where('Cantiere', 'array-contains', sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
        const itemsMac = [];
        querySnapshot.forEach((doc) => {
          var data=doc.data();
          console.log(data);
          if(data.NomeMacchina!="NESSUNA"){
            itemsMac.push( { ... data, id: doc.id } );
          }
        });
        console.log("ITEMSMAC",itemsMac);
        setMacchinari(itemsMac);
      });
    }else if(sessionStorage.getItem('Role') == 'Admin_Zona' && sessionStorage.getItem('Cantiere') !== null){
      macTable.orderBy('Zona', 'asc').where('Zona', 'array-contains', sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
        const itemsMac = [];
        querySnapshot.forEach((doc) => {
          var data=doc.data();
          if(data.NomeMacchina!="NESSUNA"){
            itemsMac.push( { ... data, id: doc.id } );
          }
        });
        setMacchinari(itemsMac);
      });
    }else{
      macTable.orderBy('Cantiere', 'asc').onSnapshot((querySnapshot) => {
        const itemsMac = [];
        querySnapshot.forEach((doc) => {
          var data=doc.data();
          if(data.NomeMacchina!="NESSUNA"){
            itemsMac.push( { ... data, id: doc.id } );
          }
        });
        setMacchinari(itemsMac);
      });
    }
  }


  /*CHANGE PER IMPLEMENTAZIONE RUOLI*/
  const [ruoli, setRuoli] = useState([]);
  const role = firebase.firestore().collection('Ruoli');
  /*END CHANGE IMPLEMENTAZIONE RUOLI*/

  /*CHANGE PER IMPLEMENTAZIONE NOTIFICHE*/
  const notify = firebase.firestore().collection('Notifiche');
  const [notifiche, setNotifiche] = useState([]);
  /*END CHANGE PER IMPLEMENTAZIONE NOTIFICHE*/

  /*CHANGE PER IMPLEMENTAZIONE RUOLI*/

  /**
   * Funzione che ottiene il ruolo dell'utente loggato
   */
  function getRuoli() {
      role.where('Utente', '==', userLogged.email).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setRuoli(items);
        
      });
  }


  const onManutenzioneDone = (id, label, riparazione) => {
    const db = firebase.firestore();

    if (window.confirm("Vuoi effettuare la manutenzione per " + label +"?")) {
      alert("Manutenzione per " + label + " effettuata")
      db.collection("Manutenzione").doc(id).update({ [riparazione]: 0 }); 
    }
  }

 /**
  * Funzione che ottiene le manutenzioni eseguite filtrando per ruolo e cantiere/zona selezionato/a
  */
  function getEseguite(){
    const role = window.sessionStorage.getItem('Role');
    const cantiere = window.sessionStorage.getItem('Cantiere');
    if(role=="Admin") {
        ese.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setEseguite(items);
          
        });
    } else if(role=="Admin_Cantiere") {   
      macTable.where("Cantiere","array-contains", cantiere).where("NomeMacchina","!=","NESSUNA").onSnapshot((querySnapshot)=>{ //Da spostare nuovamente su manutenzioni
        const elencoMac=[];
        querySnapshot.forEach((doc)=>{
          elencoMac.push(doc.data().Telaio);
        })   
        ese.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            if(elencoMac.indexOf(doc.data().Telaio)!==-1){ //Sopperisce a limti firebase (array max di 10 elementi)
              items.push( { ... doc.data(), id: doc.id } ); 
            }
          });
          setEseguite(items);
        });
      })
    } else if(role=="Admin_Zona") {
      macTable.where("Zona","array-contains", cantiere).where("NomeMacchina","!=","NESSUNA").onSnapshot((querySnapshot)=>{
        const elencoMac=[];
        querySnapshot.forEach((doc)=>{
          elencoMac.push(doc.data().Telaio);
        })   
        ese.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            if(elencoMac.indexOf(doc.data().Telaio)!==-1){ //Sopperisce a limti firebase (array max di 10 elementi)
              items.push( { ... doc.data(), id: doc.id } );
            }
          });
          setEseguite(items);
        });
      })
    }
  }

  /**
   * Funzione che rileva tutti i codici interventi a sistema
   */
    function getInterventi(){
    int.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setInterventi(items); 
        
      });
  }

/**
 * Funzione che ottiene tutti i rilievi ore dei macchinari presenti a sistema
 */
  function getRilievo() {
        ril.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setRilievi(items);
        });
  }

  function getOreIntervento(numeroTelaio,intervento,threshold){
    var maxData='1900-01-01';
    for (const i in eseguite){
      if(maxData < eseguite[i].Data && eseguite[i].Telaio==numeroTelaio && eseguite[i].CodiceManutenzione==intervento){
        maxData=eseguite[i].Data;
      }
    }
    if(maxData=='1900-01-01'){
      return threshold;
    }
    for (const i in eseguite){
      if(eseguite[i].Data==maxData && eseguite[i].Telaio==numeroTelaio && eseguite[i].CodiceManutenzione==intervento){
        
        return Math.round(eseguite[i].OreMacchina)+Math.round(threshold);
      }
    }
  }
   /**
   * Funzione che ottiene tutti i dati dei macchinari 4.0 filtrando per data
   */
    function getDataDashboard() {  
      setShowLayerCaricamento(true);
      let canId=dizionarioMacchinari.get(dropdownMacchinari)
      dataDashboard.where("timeStamp",">=",dataInizio).where("timeStamp","<=",dataFine).where("canId","==",canId).onSnapshot((querySnapshot)=>{
        const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          
          setElencoDatiDashboard(items)    
          let maxOreVeicolo=0;
          let maxDataStemp=moment(new Date('2000-01-17T03:24:00')); 
          let livelloCarburante;
          let dataSetGiriMotoreApp=[];
          let dataSetTempOlioApp=[]
          let dataSetTensioneApp=[];
          let dataSetCoolTempApp=[];
          let dataSetPressioneFiltroApp=[];
          let carburanteUtilizzato;
          let statoInterruttoreOlioIdApp;
          let datasetOrder=[]; //dataset per ordinare in ordine crescente i timestamp
          items.forEach((data)=>{       
            //Genero i dataset dei grafici
            dataSetGiriMotoreApp.push({timeStamp: data.timeStamp,giriMinuto:data.engSpeed}) 
            dataSetTempOlioApp.push({timeStamp:data.timeStamp,temperatura:data.hydTemp})
            dataSetTensioneApp.push({timeStamp:data.timeStamp,tensione:data.batteryPotentialPowerInput})
            dataSetCoolTempApp.push({timeStamp: data.timeStamp,temperatura:data.coolTemp})
            dataSetPressioneFiltroApp.push({timeStamp: data.timeStamp,pressione:data.aftPartFilterPress})  
            datasetOrder.push({timeStamp:data.timeStamp,enableSwitchPTOEngFlywheel:data.enableSwitchPTOEngFlywheel})
            if(maxOreVeicolo<data.totalVehicleHours){ //Tra tutti i dati prendo il documento con le ore più alte
              maxOreVeicolo=Math.round(data.totalVehicleHours);
            }
            if(moment(data.timeStamp).isSameOrAfter(moment(maxDataStemp))){ //Tra tutti i dati prendo il documento con timeStamp più alto,in modo da sapere l'ultimo livello di gasolio
              maxDataStemp=moment(data.timeStamp);
              livelloCarburante=data.fuelLevel1;
              carburanteUtilizzato=data.totUsedFuel;
              statoInterruttoreOlioIdApp=data.intResIdrOil;
            }
          })
          //Riordino i dataset in base al loro timestamp
          dataSetGiriMotoreApp.sort((a,b) => new moment(a.timeStamp) - new moment(b.timeStamp))
          dataSetTempOlioApp.sort((a,b) => new moment(a.timeStamp) - new moment(b.timeStamp))
          dataSetTensioneApp.sort((a,b) => new moment(a.timeStamp) - new moment(b.timeStamp))
          datasetOrder.sort((a,b) => new moment(a.timeStamp) - new moment(b.timeStamp))
          let oreLame=0;
          let calcolaOre=false;
          let timeStampAccensioneLame;
          datasetOrder.forEach((data)=>{
            if(data.enableSwitchPTOEngFlywheel==1 && !calcolaOre){
              calcolaOre=true;
              timeStampAccensioneLame=moment(data.timeStamp);  
            }else if(data.enableSwitchPTOEngFlywheel==0 && calcolaOre ){
              calcolaOre=false;
              var duration=moment.duration(moment(data.timeStamp).diff(timeStampAccensioneLame));
              if(duration.days()==0){
                oreLame=parseFloat(Number(oreLame)+Number(duration.asHours()),10).toFixed(1); 
              } 
            }
          })
          if(oreLame>maxOreVeicolo){
            setErroreOreLame(true);
          }
          setOreVeicolo(maxOreVeicolo);
          setLivelloCarburante(livelloCarburante);
          setDataSetGiriMotore(dataSetGiriMotoreApp);
          setDataSetTempOlio(dataSetTempOlioApp);
          setDataSetTensione(dataSetTensioneApp);
          setDataSetCoolTemp(dataSetCoolTempApp);
          setDataSetPressioneFiltro(dataSetPressioneFiltroApp);
          setStatoInterruttoreOlioIdr(statoInterruttoreOlioIdApp);
          if(maxOreVeicolo!==0 && carburanteUtilizzato!==0){
            setConsumoMedio(parseFloat(Number(carburanteUtilizzato/maxOreVeicolo)).toFixed(3));
          }else{
            setConsumoMedio();
          }
          setOreLame(oreLame);
          setShowLayerCaricamento(false);
      })
    }

  
    /**
     * Funzione che filtra i macchinari 4.0 da mostrare in base al ruolo dell'utente e al cantiere/zona selezionato/a
     *
     */
    function getMacchinari() {
     if (sessionStorage.getItem('Role') == 'Admin_Cantiere' && sessionStorage.getItem('Cantiere') !== null) {
      macTable.where('Cantiere', 'array-contains', sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
          const items = [];
          const map=new Map();
          querySnapshot.forEach((doc) => {
            var dati=doc.data();
            if(dati.CanId!==""){  
              map.set(dati.Telaio+" "+dati.NomeMacchina,dati.CanId);
              items.push( { ... doc.data(), id: doc.id } );
            } 

          });
          
          setDizionarioMacchinari(map);
          setElencoMacchinari(items);
          if (items[0] !== undefined) {
            setDropDownMacchinari(items[0].Telaio+" "+items[0].NomeMacchina)
          }
        });
    } else if (sessionStorage.getItem('Role') == 'Admin_Zona' && sessionStorage.getItem('Cantiere') !== null){
      macTable.where('Zona', 'array-contains', sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
          const map=new Map();
          const items=[];
          querySnapshot.forEach((doc) => {
            var dati=doc.data();
            if(dati.CanId!==""){   
              map.set(dati.Telaio+" "+dati.NomeMacchina,dati.CanId);
              items.push( { ... doc.data(), id: doc.id } );
            }
          });
          setDizionarioMacchinari(map);
          if (items[0] !== undefined) {
            setDropDownMacchinari(items[0].Telaio+" "+items[0].NomeMacchina)
          }
        });
    } else {
      macTable.onSnapshot((querySnapshot) => {
          const map=new Map();
          const items=[];
          querySnapshot.forEach((doc) => {
            var dati=doc.data();
            if(dati.CanId!==""){       
              map.set(dati.Telaio+" "+dati.NomeMacchina,dati.CanId);
              items.push( { ... doc.data(), id: doc.id } );
            }
          });
          setDizionarioMacchinari(map);
          setElencoMacchinari(items);   
          if (items[0] !== undefined) {
            setDropDownMacchinari(items[0].Telaio+" "+items[0].NomeMacchina)
          }
        });
    }
  }
  

    useEffect(()=>{
      if(dizionarioMacchinari.size>0 && dropdownMacchinari!==""){
        getDataDashboard();
      }
    },[dizionarioMacchinari,dropdownMacchinari,dataInizio,dataFine])
    
    function downloadDatiMacchina(){
      setShowLayerCaricamento(true);
      const doc = new jsPDF();
      doc.setFontSize(22);
      doc.addImage("./images/Logo1.png", 'JPEG',65, 70 , 80, 90);
      doc.text(30, 180, dropdownMacchinari);
      doc.text(30, 200, "Report da: "+moment(dataInizio).format("DD/MM/YYYY")+" a: "+moment(dataFine).format("DD/MM/YYYY"));
      doc.addPage();
      const oreVeicolo = document.getElementById('oreVeicolo');
      const livelloCarburante= document.getElementById('livelloCarburante');
      const oreLame= document.getElementById('oreLame');
      const consumoMedio= document.getElementById('consumoMedio');
      const graficoGiriMotore=document.getElementById('graficoGiriMotore');
      const graficoTemperaturaOlio=document.getElementById('graficoTemperaturaOlio');
      const graficoTensione=document.getElementById('graficoTensione');
      const graficoPressioneFiltro=document.getElementById('graficoPressioneFiltro');
      html2canvas((oreVeicolo),{
        width: oreVeicolo.width,
        height: oreVeicolo.height,
        useCORS:true,
      }).then((canvas) => {
          var image = canvas.toDataURL('image/jpg', 1.0);
          doc.addImage(image, 'JPG', 2, 10,100,40);
          html2canvas((livelloCarburante),{
            width: livelloCarburante.width,
            height: livelloCarburante.height,
            useCORS:true,
          }).then((canvas) => {
              var image = canvas.toDataURL('image/jpg', 1.0);
              doc.addImage(image, 'JPG', 107, 10,100,40);
              html2canvas((oreLame),{
                width: oreLame.width,
                height: oreLame.height,
                useCORS:true,
              }).then((canvas) => {
                  var image = canvas.toDataURL('image/jpg', 1.0);
                  doc.addImage(image, 'JPG', 2, 50,100,40);
                  html2canvas((consumoMedio),{
                    width: consumoMedio.width,
                    height: consumoMedio.height,
                    useCORS:true,
                  }).then((canvas) => {
                      var image = canvas.toDataURL('image/jpg', 1.0);
                      doc.addImage(image, 'JPG', 107, 50,100,40);
                      html2canvas((graficoGiriMotore),{
                        width: graficoGiriMotore.width,
                        height: graficoGiriMotore.height,
                        useCORS:true,
                      }).then((canvas) => {
                          var image = canvas.toDataURL('image/jpg', 1.0);
                          doc.addImage(image, 'JPG', 5, 110,200,100);
                          doc.addPage();
                          html2canvas((graficoTemperaturaOlio),{
                            width: graficoTemperaturaOlio.width,
                            height: graficoTemperaturaOlio.height,
                            useCORS:true,
                          }).then((canvas) => { 
                              var image = canvas.toDataURL('image/jpg', 1.0);
                              doc.addImage(image, 'JPG', 5, 15,200,100);
                              html2canvas((graficoTensione),{
                                width: graficoTensione.width,
                                height: graficoTensione.height,
                                useCORS:true,
                              }).then((canvas) => { 
                                  var image = canvas.toDataURL('image/jpg', 1.0);
                                  doc.addImage(image, 'JPG', 5, 120,200,100);
                                  doc.addPage();
                                  html2canvas((graficoPressioneFiltro),{
                                    width: graficoPressioneFiltro.width,
                                    height: graficoPressioneFiltro.height,
                                    useCORS:true,
                                  }).then((canvas) => { 
                                      var image = canvas.toDataURL('image/jpg', 1.0);
                                      doc.addImage(image, 'JPG', 5, 15,200,100);
                                      doc.addPage();
                                      var headGiriMotore=[];
                                      headGiriMotore.push({header:"Giri Motore",dataKey: "giriMinuto"});
                                      headGiriMotore.push({header:"TimeStamp",dataKey: "timeStamp"});
                                      autoTable(doc, {
                                        columns: headGiriMotore,
                                        body: datasetGiriMotore,
                                      });
                                      doc.addPage();
                                      var headTempOlio=[];
                                      headTempOlio.push({header:"Temperatura fluido idraulico",dataKey: "temperatura"});
                                      headTempOlio.push({header:"TimeStamp",dataKey: "timeStamp"});
                                      autoTable(doc, {
                                        columns: headTempOlio,
                                        body: datasetTempOlio,
                                      });
                                      doc.addPage();
                                      var headTensioneBatteria=[];
                                      headTensioneBatteria.push({header:"Tensione batteria",dataKey: "tensione"});
                                      headTensioneBatteria.push({header:"TimeStamp",dataKey: "timeStamp"});
                                      autoTable(doc, {
                                        columns: headTensioneBatteria,
                                        body: datasetTensione,
                                      });
                                      doc.addPage();
                                      var headTempLiquidoRaffr=[];
                                      headTempLiquidoRaffr.push({header:"Temperatura liquido raffredamento",dataKey: "temperatura"});
                                      headTempLiquidoRaffr.push({header:"TimeStamp",dataKey: "timeStamp"});
                                      autoTable(doc, {
                                        columns: headTempLiquidoRaffr,
                                        body: dataSetCoolTemp,
                                      });
                                      doc.addPage();
                                      var headPressioneFiltro=[];
                                      headPressioneFiltro.push({header:"Pressione differenziale filtro antiparticolato",dataKey: "pressione"});
                                      headPressioneFiltro.push({header:"TimeStamp",dataKey: "timeStamp"});
                                      autoTable(doc, {
                                        columns: headPressioneFiltro,
                                        body: dataSetPressioneFiltro,
                                      });
                                      setShowLayerCaricamento(false);
                                      doc.save();
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    }

  
    useEffect(() => {
      getRuoli();
      getManutenzioneMacchinari();
      getMacchinari();
     // getDataDashboard();
    }, []);

    useEffect(() => {
      getRilievo();
      getInterventi();
    }, []);
  
    useEffect(() => {
      getEseguite();
    }, []);
  
  
  
    if (sessionStorage.getItem("firebase:authUser:AIzaSyDrje9pCvY8yV0DJ8fz3MsI05pYsS4Tafc:[DEFAULT]") == null) {
      return <Redirect to='/'  />
    }

    function onChangeMacchinario(option){
      setDropDownMacchinari(option.value);
    }
  
  
  
  if (sessionStorage.getItem("firebase:authUser:AIzaSyDrje9pCvY8yV0DJ8fz3MsI05pYsS4Tafc:[DEFAULT]") == null) {
    return <Redirect to='/'  />
  }

  const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyDrje9pCvY8yV0DJ8fz3MsI05pYsS4Tafc:[DEFAULT]"));

    //Funzione per  i tab
    const onActive = (nextIndex) =>{setSelectedTab(nextIndex);
      if(nextIndex!=0){
        setDropDownMenu("");
        setMacchinarioSel("");
        setNessunDato("");
      }
     }

     function maxDateCalc(all_dates) {
      var max_dt = all_dates[0],
      max_dtObj = new Date(all_dates[0]);
      var i=0;
      all_dates.forEach(function(dt, index){
      if ( new Date( dt ) > max_dtObj){
        max_dt = dt;
        max_dtObj = new Date(dt);
        i=index
      }
      });
       return {date:max_dt,index:i};
     }

  

  return (
    <Grommet theme={theme} themeMode="dark" className="font-link">
      <ResponsiveContext.Consumer>
        {size => (
        <Box flex={false} fill>
            <Tabs activeIndex={selectedTab} onActive={onActive}>
                <Tab title="Manutenzione">
                  <Box direction="row">
                  <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
                  <Box flex height="92.5vh">
                    <Box flex="false" pad="medium" direction="row" gap="medium" align="center">
                      <Text color="#04671E" margin="small" style={{fontWeight:'bold'}}>FILTRO MACCHINARIO: </Text>
                      <Select
                          placeholder="Seleziona macchinario"
                          options={[... new Set(macchinari.sort(function(a, b) { return a.NomeMacchina.localeCompare(b.NomeMacchina)}))].map((macchina)=>macchina.NomeMacchina)}
                          value={dropdownMenu}
                          onChange={event =>
                            setDropDownMenu(event.value)
                          }
                        />
                        <Divider orientation="vertical"/>
                        <Button style={{width:'350px',fontSize:'10px',background:"#0350281A",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#006837',borderWidth:"3px"}} onClick={()=>setMostraLayer(true)}>
                          <Box pad="small" direction="row" align="center" gap="medium">
                              <Text size="small" color="#006837">INSERISCI INTERVENTO DI MANUTENZIONE</Text>
                          </Box>
                        </Button>
                        <Button style={{width:'250px',fontSize:'10px',background:"#0350281A",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#006837',borderWidth:"3px"}} onClick={()=>{setMostraLayerRilievo(true);setMacchinaSelOre("")}}>
                          <Box pad="small" direction="row" align="center" gap="medium">
                              <Text size="small" color="#006837">INSERISCI RILIEVO ORE</Text>
                          </Box>
                        </Button>
                        {
                        mostraLayer && (
                          <Layer full={window.screen.width<1100?true:false} onEsc={()=>setMostraLayer(false)} onClickOutside={()=>setMostraLayer(false)}>
                          <Box width="80vw" height="100%" round="medium" pad="15px" background="#E5EDE9">
                            <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setMostraLayer(false)}/>}></Button>
                            <Box round="medium" margin="small" alignSelf='center' background="white">
                              <AddEse onEscLayer={setMostraLayer} />                        
                            </Box>
                            <Box height="180px" overflow="auto">
                              <Table>
                                <TableHeader style={{fontWeight:'bold', color:'#006837'}}>
                                  <TableRow>
                                    <TableCell scope="col" border="bottom">
                                      Codice 
                                    </TableCell>
                                    <TableCell scope="col" border="bottom">
                                      Data 
                                    </TableCell>
                                    <TableCell scope="col" border="bottom">
                                      Macchinario
                                    </TableCell>
                                    <TableCell scope="col" border="bottom">
                                      Ore Macchina 
                                    </TableCell>
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                  {eseguite.map(eseguito => (
                                  <AnagraficheManutenzioniEseguite ruolo={ruoli[0].Ruolo} eseguito={eseguito}/>
                                  ))}
                                </TableBody>
                              </Table>  
                            </Box>
                          </Box>
                      </Layer>
                        )
                      }
                       {
                        mostraLayerRilievo && (
                          <Layer full={window.screen.width<1100?true:false} onEsc={()=>setMostraLayerRilievo(false)} onClickOutside={()=>setMostraLayerRilievo(false)}>
                          <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                            <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setMostraLayerRilievo(false)}/>}></Button>
                            <Box round="medium" margin="small" alignSelf='center' background="white">
                              <AddRil parcoMacchine={true} Telaio={(macchinaSelOre!==undefined && macchinaSelOre.Telaio!==undefined)?macchinaSelOre.Telaio:""} Modello={(macchinaSelOre!==undefined && macchinaSelOre.NomeMacchina!==undefined)?macchinaSelOre.NomeMacchina:""}  onEscLayer={setMostraLayerRilievo} />                        
                            </Box>
                          </Box>
                      </Layer>
                        )

                      }
                      </Box>
                    <Box pad="small" align="center">
                      {
                        nessunDato?
                          <Box margin="3%" alignSelf="center">
                            <Text weight="bold">NESSUN INTERVENTO PRESENTE A SISTEMA PER QUESTA MACCHINA</Text>
                          </Box>
                        :
                   
                      <Grid 
                        rows={['auto', 'auto']}
                        columns={[ 'auto','auto', 'auto',, 'auto']}
                        gap="medium"
                        pad="small"> 
                      {/*/*TK-24 Modulo per la gestione della manutenzione - START*/}  
                      {
                          
                            interventi.map(intervento => {

                            
                              //Filtro dalle manutenzioni eseguite quelle con lo stesso codice intervento dell'oggetto uscito dal .map e tengo solo quelli del macchinario selezionato
                              if(macchinarioSel!==null){
                                for(let i=0;i<eseguite.length;i++){
                                  if(eseguite[i].CodiceManutenzione==intervento.Codice &&  eseguite[i].Telaio==macchinarioSel.Telaio){
                                    eseguiteMacchinarioSel.push(eseguite[i]);
                                  }                    
                                }
                              }
                              
                              //Trovo tra le manutenzioni filtrate quello con la data maggiore e mi salvo il suo indice nell'array, per poi successivamente poterci accedere più facilmente
                              var maxDate=null;
                              var index=null;
                              if(eseguiteMacchinarioSel.length>0){
                                var obj=maxDateCalc(eseguiteMacchinarioSel.map((manutenzione)=>manutenzione.Data));
                                maxDate=obj.date;
                                index=obj.index;
                              }
                              var value;     
                              if(macchinarioSel!==null && macchinarioSel.OreCalcolate!==undefined){
                                value=macchinarioSel.OreCalcolate>=macchinarioSel[intervento.Codice]?macchinarioSel[intervento.Codice]:macchinarioSel.OreCalcolate;
                              }
                              var percentage1;
                              var percentage2;
                              if(macchinarioSel!==null && macchinarioSel[intervento.Codice]!==undefined){
                                percentage1=macchinarioSel[intervento.Codice]*0.25;
                                percentage2=macchinarioSel[intervento.Codice]*0.50;
                              }
                              const center = 250 / 2;
                              return(
                            macchinarioSel!==null?
                              macchinarioSel[intervento.Codice] !== undefined &&  macchinarioSel[intervento.Codice] !== "" ?
                                
                              <Box height="300px" direction="column" align="center">
                                <CircularProgressbar 
                                  value={value}
                                  maxValue={macchinarioSel[intervento.Codice]}
                                  minValue={maxDate==null && tempoMacchinaIniziale==null?0:maxDate==null &&  tempoMacchinaIniziale!=null?tempoMacchinaIniziale:eseguiteMacchinarioSel[index].OreMacchina} //Se non c’è ultima manutenzione e nemmeno ora_macchina iniziale allora è 0, altrimenti se non c’è ultima manutenzione ma è presente ora_macchina iniziale, allora il minValue è uguale a ora_macchina iniziale
                                  text={macchinarioSel.OreCalcolate.toFixed(2)+ " di " + macchinarioSel[intervento.Codice]} 
                                  strokeWidth="9"
                                  styles=
                                  {value<=percentage1?
                                    buildStyles({
                                      textSize: '10px',
                                      textColor:'green',
                                      pathColor: `green`,
                                    })
                                  :value>percentage1 && value<=percentage2?
                                    buildStyles({
                                      textSize: '10px',
                                      textColor:'orange',
                                      pathColor: `orange`,
                                    })
                                  :
                                  buildStyles({
                                    textSize: '10px',
                                    textColor:'red',
                                    pathColor: `red`,
                                  })
                                  }
                                    />
                                <br/><br/>
                                <Text>{intervento.Codice}</Text>             
                              </Box>
                            :
                              null
                              :
                              null
                            
                             )}
                            
                              
                              )}
                    {/*/*TK-24 Modulo per la gestione della manutenzione - END*/}
                      </Grid>
                         }
                    </Box>
                  </Box>
                  </Box>
              </Box>
              </Tab>
              <Tab title="Parco Macchine">
              <Box width="100%" height="94vh" align="" pad="medium" overflow="auto">
                <Table>
                  <TableHeader style={{fontWeight:'bold', color:'#006837'}}>
                    <TableRow>
                      <TableCell scope="col" border="bottom">
                        Nome Macchina 
                      </TableCell>
                      <TableCell scope="col" border="bottom">
                        Telaio 
                      </TableCell>
                      <TableCell scope="col" border="bottom">
                        Ore calcolate
                      </TableCell>
                      <TableCell scope="col" border="bottom"/>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {macchinari.map(macchina => (
                    <TableRow>
                    <TableCell scope="col" border="bottom">
                      {macchina.NomeMacchina} 
                    </TableCell>
                    <TableCell scope="col" border="bottom">
                      {macchina.Telaio}
                    </TableCell>
                    <TableCell scope="col" border="bottom">
                      {Number.parseFloat(macchina.OreCalcolate).toFixed(2)}
                    </TableCell>
                    <TableCell scope="col" border="bottom">
                      <Box direction="row" gap="medium">
                        <Button style={{width:'200px',fontSize:'10px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() =>{setSelectedTab(0);setDropDownMenu(macchina.NomeMacchina)}}>
                          <Box pad="small" direction="row" align="center" gap="small">
                              <Configure color="white" />
                              <Text size="small" color="white">Manutenzione</Text>
                          </Box>
                        </Button>
                        <Button style={{width:'200px',fontSize:'10px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setMacchinaSelOre(macchina);setMostraLayerRilievo(true)}}>
                          <Box pad="small" direction="row" align="center" gap="small">
                              <ClockIcon color="white" />
                              <Text size="small" color="white">Rilievo ore</Text>
                          </Box>
                      </Button>
                    </Box>
                    </TableCell>  
                  </TableRow> 
                    ))}
                  </TableBody>
                </Table>
              </Box>  
              {
                mostraLayerRilievo && (
                  <Layer full={window.screen.width<1100?true:false} onEsc={()=>setMostraLayerRilievo(false)} onClickOutside={()=>setMostraLayerRilievo(false)}>
                  <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                    <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setMostraLayerRilievo(false)}/>}></Button>
                    <Box round="medium" margin="small" alignSelf='center' background="white">
                      <AddRil parcoMacchine={true} Telaio={(macchinaSelOre!==undefined && macchinaSelOre.Telaio!==undefined)?macchinaSelOre.Telaio:""} Modello={(macchinaSelOre!==undefined && macchinaSelOre.NomeMacchina!==undefined)?macchinaSelOre.NomeMacchina:""}  onEscLayer={setMostraLayerRilievo} />                        
                    </Box>
                  </Box>
              </Layer>
                )
              }       
              </Tab>
              {
                  props.licenzaDashboardMac?
                  <Tab title="Dati 4.0">
                    <Box height="92vh" overflow="auto">
                    <BoxMaterial
                      component="main"
                      sx={{
                        flexGrow: 1,
                        py: 8
                      }}
                    >
                  <Box direction="column" alignSelf="center" align="center" gap="large">
                    <Box direction="row" gap="small" width="700px" align="center" >
                      <Text color="#04671E" margin="small" style={{fontWeight:'bold'}}>SELEZIONA MACCHINA </Text>
                      <Select
                      options={[...new Set(elencoMacchinari.sort(function(a, b) { return a.Telaio.localeCompare(b.Telaio) }).map(macchinario => (macchinario.Telaio +" "+ macchinario.NomeMacchina)))]}
                      value={dropdownMacchinari}
                      onChange={(option) => onChangeMacchinario(option)}
                      />
                    </Box>
                    <Box width="400px" height="130px">
                      <StatoInterruttoreOlioIdr statoInterruttoreOlioIdr={statoInterruttoreOlioIdr}/>
                    </Box>
                  <Container maxWidth={false}>
                    <GridMaterial
                      container
                      spacing={4}
                    >
                      <GridMaterial
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                      >
                        <OreVeicolo oreVeicolo={oreVeicolo} id="oreVeicolo" />
                      </GridMaterial>
                      <GridMaterial
                        item
                        xl={3}
                        lg={3}
                        sm={6}
                        xs={12}
                      >
                        <LivelloCarburante livelloCarburante={livelloCarburante} id="livelloCarburante"/>
                      </GridMaterial>
                      <GridMaterial
                        item
                        xl={3}
                        lg={3}
                        sm={6}
                        xs={12}
                      >
                        <OreLame oreLame={oreLame} erroreOreLame={erroreOreLame} id="oreLame"/>
                      </GridMaterial>
                      <GridMaterial
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                      >
                        <ConsumoMedio consumoMedio={consumoMedio} id="consumoMedio"/>
                      </GridMaterial>
                      <Box direction="row" width="100%" gap="small" pad="small">
                        <GridMaterial
                          item
                          lg={8}
                          md={12}
                          xl={9}
                          xs={12}
                        >
                          <GraficoGiriMotore datasetGiriMotore={datasetGiriMotore} id="graficoGiriMotore"/>
                        </GridMaterial>
                        <GridMaterial
                          item
                          lg={8}
                          md={12}
                          xl={9}
                          xs={12}
                        >
                          <GraficoTemperaturaOlio datasetTempOlio={datasetTempOlio} dataSetCoolTemp={dataSetCoolTemp} id="graficoTemperaturaOlio"/>
                        </GridMaterial>
                      </Box>
                      <Box direction="row" width="100%" gap="small" pad="small">
                        <GridMaterial
                          item
                          lg={8}
                          md={12}
                          xl={9}
                          xs={12}
                        >
                          <GraficoTensione datasetTensione={datasetTensione} id="graficoTensione"/>
                        </GridMaterial>
                        <GridMaterial
                          item
                          lg={8}
                          md={12}
                          xl={9}
                          xs={12}
                        >
                          <GraficoPressioneFiltro datasetPressioneFiltro={dataSetPressioneFiltro} id="graficoPressioneFiltro"/>
                        </GridMaterial>
                      </Box>
                    </GridMaterial>
                  </Container>
                  </Box>
                  <Box margin='small' gap="small" alignSelf="end" style={{position:'fixed',bottom:'3%',zIndex:'5',right:'2%'}}>             
                      <Box alignSelf="end">
                        <Button
                          icon={<Download color="white"/>}
                          onClick={downloadDatiMacchina}
                          style={{fontSize:'28px',background:'#8FBCA7', padding:'10px',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}>
                        </Button>
                      </Box>
                      <DropButton
                              icon={<Calendar color='white'/>}
                              style={{fontSize:'28px',background:'#006837', padding:'10px',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}
                              dropAlign={{ bottom: 'bottom', right: 'left' }}
                              dropContent={
                                <>
                                <Box pad="medium" gap="medium">
                                  <Box direction="row" gap="small">
                                    <Text>Data inizio: </Text>
                                    <DateInput
                                      format="mm/dd/yyyy"
                                      value={dataInizio}
                                      onChange={({ value }) => {setDataInizio(moment(value).format())}}
                                      dropProps={{align:{top:"top"}}}
                                      calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                                    />
                                    </Box>
                                    <Box direction="row" gap="small">
                                    <Text>Data Fine: </Text>
                                    <DateInput
                                      format="mm/dd/yyyy"
                                      value={dataFine}
                                      onChange={({ value }) => {setDataFine(moment(value).format())}}
                                      dropProps={{align:{top:"top"}}}
                                      calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                                    />
                                    </Box>
                                  </Box>
                                </>
                              
                              }
                            />
                    </Box>
                </BoxMaterial>
                </Box>
                  </Tab>
                  :
                  null
                  }
                   {showLayerCaricamento && (
                       <Layer onEsc={()=>setShowLayerCaricamento(false)}>
                        <Box align="center" gap="medium" pad="small">
                          <CircularProgress style={{'color': 'green'}} variant="determinate" value={90} />
                          <Text>Caricamento...</Text>
                        </Box>
                      </Layer>
                    )
                    }
            </Tabs>
        </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;
