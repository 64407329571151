import React, { useState, useEffect } from "react";
import { Box, Grommet, Button, TextInput, Table, TableHeader, TableBody, TableRow, TableCell, DateInput, Select } from 'grommet';
import { Edit, Checkmark, FormClose, Trash, Map as Detail } from "grommet-icons";
import firebase from '../firebase';
import moment from 'moment';


export default function AnagraficheAtt({ attivita,ruolo }) {

const [modify, setModify] = useState(false);

const dip = firebase.firestore().collection('Dipendenti');
const [Dipendente, setDropdownDipSel] = useState("");
const [dipendenti, setDipendenti] = useState([]);

const mac = firebase.firestore().collection('Macchinari');
const [macchine, setMacchinari] = useState([]);
const [Macchina, setDropdownMacSel] = useState('');

const lav = firebase.firestore().collection('Lavori');
const [lavori, setLavori] = useState([]);
const [Lavoro, setDropdownLavSel] = useState('');

const prod = firebase.firestore().collection('Prodotti');
const [prodotti, setProdotti] = useState([]);
const [Prodotto, setDropdownProdSel] = useState('');

const aree = firebase.firestore().collection('Aree');
const [Zona, setDropdownZonaSel] = useState('');
const [zona, setZona] = useState([]);

const [Ordinaria, setOrdinaria] = useState("");

const [Data, setData] = useState("");

const [Messaggio, setMessaggio] = useState("");

const [MessaggioLav, setMessaggioLav] = useState("");

const [Durata, setDurataAtt] = useState("");
	
	
/**
 * Funzione che rileva tutti i dipendenti presenti a sistema
 */
  function getDipendenti() {

        dip.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setDipendenti(items);
          
        });
  
  }

 /**
   * Funzione che ritorna la data in formato dd-MM-yyyy
   */
  function dataTrasformation(data){
	  if(data == ''){
	  	var newData = "";
	  	return newData;
	  }
	  else{
	  	var dataArray= data.split('-');
	   	var newData=dataArray[2]+'-'+dataArray[1]+'-'+dataArray[0];
	   	return newData;
	  }        
 	}

   /*
  	Funzione che aggiorna il dipendente che ha svolta l'attività
  */
  const onUpdateDip = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Dipendente});
    setModify(false);
  }

/**
 * Funzione che rileva tutti i macchinari presenti a sistema
 */
  function getMacchinari() {
        mac.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setMacchinari(items);
        });
  }

  /*
  	Funzione che aggiorna il macchinario con cui è stata svolta l'attività
  */
  const onUpdateMac = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Macchina});
    setModify(false);
  }

 /**
 * Funzione che rileva tutti i prodotti presenti a sistema
 */
  function getProdotti() {
        prod.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setProdotti(items);         
        });
  }

   /*
  	Funzione che aggiorna il prodotto con cui è stata svolta l'attività
  */
  const onUpdateProd = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Prodotto});
    setModify(false);
  }

  /**
 * Funzione che rileva tutti i lavori presenti a sistema
 */
  function getLavori() {

        lav.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setLavori(items);
          
        });

  }

  /*
  	Funzione che aggiorna il lavoro  dell'attività
  */
  const onUpdateLav = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Lavoro});
    setModify(false);
  }

  
/**
 * Funzione che rileva tutti i cantieri/zone/adl presenti a sistema
 */
  function getAree() {

        aree.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setZona(items);
          
        });

  }

    /*
  	Funzione che aggiorna adl dell'attività
  */
  const onUpdateZona = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Zona});
    setModify(false);
  }

   /*
  	Funzione che aggiorna la data dell'attività
  */
  const onUpdateData = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Data});
    setModify(false);
  }

   /*
  	Funzione che aggiorna il messaggio dell'attività
  */
  const onUpdateMess = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Messaggio});
    setModify(false);
  }

   /*
  	Funzione che aggiorna la durata dell'attività
  */
  const onUpdateDurata = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Durata});
    setModify(false);
  }

   /*
  	Funzione che aggiorna la tipologia dell'attività
  */
  const onUpdateOrdinaria = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Ordinaria});
    setModify(false);
  }

  /*
  	Funzione che elimina un attività
  */
  const onDelete = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).delete();
    setModify(false);
  }

  const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyDrje9pCvY8yV0DJ8fz3MsI05pYsS4Tafc:[DEFAULT]"));

  useEffect(() => {
    getDipendenti();
    getMacchinari();
    getLavori();
    getProdotti();
    getAree();
  }, []);

return (
attivita.Approvata == 'si' || attivita.Approvata == 'manuale' ? 
	null 
	:
	<>
	<TableRow id={attivita.id} style={{color: attivita.Approvata == 'manuale' ? 'white' : 'black', backgroundColor: attivita.Approvata == 'si' ? '#33bd24' : (attivita.Approvata == 'no' ? '#FF6666' : (attivita.Approvata == 'partita' ? '#FFFF66' : (attivita.Approvata == 'manuale' ? 'rgb(34, 119, 178)' : '#E0E0E0')))}}>
		{/*<TableCell scope="row">
			<strong>{attivita.Approvata == 'si' ? "Terminata" : (attivita.Approvata == 'partita' ? "In corso" : (attivita.Approvata == 'manuale' ? "Chiusa Manualmente" : "Pending"))}</strong>
		</TableCell>*/}
		<TableCell scope="row">
			{ modify ?
				<>
				<Select
	        options={dipendenti.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(dipendente => (dipendente.Nome))}
	        placeholder={attivita.Dipendente}
	        value={Dipendente}
	        onChange={({ option }) => setDropdownDipSel(option)}
	      />
				<Button icon=<Checkmark /> onClick={onUpdateDip} label="" />
				</>
				:
				<>
				<strong>{attivita.Dipendente}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<Select
	              options={macchine.sort(function(a, b) { return a.NomeMacchina.localeCompare(b.NomeMacchina) }).map(macchina => (macchina.NomeMacchina))}
	              placeholder={attivita.Macchina}
	              value={Macchina}
	              onChange={({ option }) => setDropdownMacSel(option)}
	            />
				<Button icon=<Checkmark /> onClick={onUpdateMac} label="" />
				</>
				:
				<>
				<strong>{attivita.Macchina}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<Select
	        options={lavori.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(lavoro => (lavoro.Nome))}
	        placeholder={attivita.Lavoro}
	        value={Lavoro}
	        onChange={({ option }) => setDropdownLavSel(option)}
	      />
				<Button icon=<Checkmark /> onClick={onUpdateLav} label="" />
				</>
				:
				<>
				<strong>{attivita.Lavoro}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<Select
	              options={zona.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(area => (area.Nome+'-'+area.Localita))}
	              placeholder={attivita.Zona}
	              value={Zona}
	              onChange={({ option }) => setDropdownZonaSel(option)}
	            />
				<Button icon=<Checkmark /> onClick={onUpdateZona} label="" />
				</>
				:
				<>
				<strong>{attivita.Zona}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<Select
	              options={prodotti.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(prodotto => (prodotto.Nome))}
	              placeholder={attivita.Prodotto}
	              value={Prodotto}
	              onChange={({ option }) => setDropdownProdSel(option)}
	            />
				<Button icon=<Checkmark /> onClick={onUpdateProd} label="" />
				</>
				:
				<>
				<strong>{attivita.Prodotto}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<DateInput
                	format="dd/mm/yyyy"
                	value={(new Date()).toISOString()}
                	onChange={({ value }) => {setData(value.split('T')[0])}}
                	calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
              	/>
				<Button icon=<Checkmark /> onClick={onUpdateData} label="" />
				</>
				:
				<>
				<strong>{dataTrasformation(attivita.Data)}</strong>
				</>
			}
		</TableCell>
		{/*<TableCell>
			<strong>{attivita.Approvata == 'si' || attivita.Approvata == 'manuale' ? moment.parseZone(new Date(attivita.Start)).local().format('YYYY-MM-DD HH:mm:ss') : "Attività non iniziata"}</strong>
		</TableCell>
		<TableCell>
			<strong>{attivita.Approvata == 'si' || attivita.Approvata == 'manuale' ? moment.parseZone(new Date(attivita.End)).local().format('YYYY-MM-DD HH:mm:ss') : "Attività non iniziata"}</strong>
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<TextInput
				    placeholder={attivita.Messaggio}
				    onChange={e => setMessaggio(e.target.value)}
				/>
				<Button icon=<Checkmark /> onClick={onUpdateMess} label="" />
				</>
				:
				<>
				<strong>{attivita.Messaggio}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<TextInput
				    placeholder={attivita.MessaggioLav}
				    onChange={e => setMessaggioLav(e.target.value)}
				/>
				<Button icon=<Checkmark /> onClick={onUpdateMessLav} label="" />
				</>
				:
				<>
				<strong>{attivita.MessaggioLav}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<TextInput
				    placeholder={attivita.Durata}
				    onChange={e => setDurataAtt(e.target.value)}
				/>
				<Button icon=<Checkmark /> onClick={onUpdateDurata} label="" />
				</>
				:
				<>
				<strong>{attivita.Durata}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			<strong>{attivita.Approvata == 'si' || attivita.Approvata == 'manuale' ? Number(parseFloat(((Date.parse(attivita.End) - Date.parse(attivita.Start))/1000/60), 10).toFixed(0)) : 0}</strong>
		</TableCell>*/}
		<TableCell>
			{ modify ?
				<>
				<TextInput
				    placeholder={attivita.Ordinaria}
				    onChange={e => setOrdinaria(e.target.value)}
				/>
				<Button icon=<Checkmark /> onClick={onUpdateOrdinaria} label="" />
				</>
				:
				<>
				<strong>{attivita.Ordinaria}</strong>
				</>
			}
		</TableCell>
		{ ruolo == 'Admin' ?
		<TableCell>
			{ modify ?
				<>
				<Button icon=<FormClose color={attivita.Approvata == 'manuale' ? "white" : "black"}/> onClick={() => setModify(false)} label="" />
				<Button icon=<Trash color={attivita.Approvata == 'manuale' ? "white" : "black"}/> onClick={onDelete} label="" />
				</>
				:
				(attivita.Approvata == 'si' || attivita.Approvata == 'partita' ?
				<Button icon=<Detail color={attivita.Approvata == 'manuale' ? "white" : "black"}/> onClick={() => window.location.href = window.location.origin + "/dettaglio/"+attivita.id} label="" />
				:
				<>
				<Button icon=<Edit color={attivita.Approvata == 'manuale' ? "white" : "black"}/> onClick={() => setModify(true)} label="" />
				<Button icon=<Detail color={attivita.Approvata == 'manuale' ? "white" : "black"}/> onClick={() => window.location.href = window.location.origin + "/dettaglio/"+attivita.id} label="" />
				</>
				)
			}
		</TableCell>
		:
		null
		}
	</TableRow>
</>
)

}