import React, { useState, useEffect } from "react";
import { Box, Menu, Grommet, Form, FormField, Grid, Avatar,Sidebar, Card, Select, CardHeader,Nav, CardFooter, CardBody, Text, Table, TableHeader, TableBody,Image, TableRow, TableCell, Clock, TextInput, Button, Calendar, Anchor, CheckBoxGroup, WorldMap, DataChart, DataTable, Meter, Heading, Collapsible, Layer, ResponsiveContext, DropButton} from 'grommet';
import { FormClose, Home, CircleInformation, Add, UserWorker, Task, Analytics, MapLocation, Configure ,List, Manual, Windows,Notification as NotificationApp } from "grommet-icons";
import Notifications from "./components/Notifications";
import firebase from './firebase';
import  { Redirect,useLocation } from 'react-router-dom';
import WebFont from 'webfontloader';
import './css/index.css';
import AccountBox from "./components/Account";
import Anagrafiche from "./components/Anagrafiche";
import AttivitaDet1 from "./components/AttivitaDet1";
import BucheDet from "./components/BucheDet";
import Manutenzione from "./components/Manutenzione";
import Dashboard from "./components/Dashboard"
import Notifiche from "./components/Notifiche";
import { useSpring, animated } from 'react-spring'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const theme = {
  global: {
    input:{
      font:{
        weight: 0
      }
    },
    colors: {
      brand: '#006837',
      'accent-1': 'white',
      'focus': '#006837',
      "my-text-color": "",
      background: 'white',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',

    },
  },
};

const App = () => {

  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const ref = firebase.firestore().collection('Dipendenti');
  const tok = firebase.firestore().collection('Tokens');
  const att = firebase.firestore().collection('Attivita');
  /*CHANGE PER IMPLEMENTAZIONE RUOLI*/
  const [ruoli, setRuoli] = useState([]);
  const role = firebase.firestore().collection('Ruoli');
  /*END CHANGE IMPLEMENTAZIONE RUOLI*/
  
  /*CHANGE PER IMPLEMENTAZIONE NOTIFICHE*/
  const notify = firebase.firestore().collection('Notifiche');
  const [notifiche, setNotifiche] = useState([]);
  /*END CHANGE PER IMPLEMENTAZIONE NOTIFICHE*/

  /*CHANGE PER IMPLEMENTAZIONE CANTIERI*/
  let [newCantiere, setNewCantiere] = useState(sessionStorage.getItem('Cantiere') !== null ? sessionStorage.getItem('Cantiere') : "");
  const [cantieri, setCantieri] = useState([]);
  const cant = firebase.firestore().collection('Aree');
  const [dipruolo, setDipRuolo] = useState([]);
  const [page,setPage]=React.useState("Home"); //Da cambiare come parametro scheda home
  const [nomeDip,setNomeDip]=React.useState(" - ");
  const refruolo = firebase.firestore().collection('Dipendenti');
  const mac = firebase.firestore().collection('Macchinari');
  const location = useLocation();
  const licenze=firebase.firestore().collection("Licenze");
  const [licenzaDashboardAtt,setLicenzaDashboardAtt]=React.useState(false);
  const [licenzaDashboardMac,setLicenzaDashboardMac]=React.useState(false);
  const [showSideBarMob,setShowSideBarMob]=React.useState(false);
  const [landScapeError,setLandScapeError]=React.useState(false);
  const [smallSideBar,setSmallSideBar]=React.useState(false);
  const [dipendenteLoggato,setDipendenteLoggato]=React.useState(null);
  
  const userSession = {
                        user: {
                          name: "Admin 1",
                          thumbnail: "https://s.gravatar.com/avatar/b7fb138d53ba0f573212ccce38a7c43b?s=80"
                        },
                        items: [
                          {
                            label: "Logout",
                            href: "#"
                          }
                        ]
                      };
  const data = [{ date: '2021-08-20', amount: 2 }, { date: '2021-08-21', amount: 47 }, { date: '2021-08-22', amount: 33 }];

  /*
    Funzione che in base all'email dell'utente loggato trova il nome e il cognome
  */
  function getDipendenti() {
      ref.onSnapshot((querySnapshot) => {
        let name=" - ";
        querySnapshot.forEach((doc) => {
          if(doc.data().email==userLogged.email){
            name=doc.data().Nome+" "+doc.data().Cognome;
          }
        });
        setNomeDip(name);
      });

  }

  /*
    Funzione che in base all'email dell'utente loggato ne rileva il ruolo
  */
  function getRuoli() {
      role.where('Utente', '==', userLogged.email).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setRuoli(items);
        sessionStorage.setItem('Role', items[0].Ruolo);
      });
      refruolo.where("email","==",userLogged.email).onSnapshot((querySnapshot)=>{
        let dipendenteLoggato;
        querySnapshot.forEach((doc)=>{
          dipendenteLoggato=doc.data();
        })
        setDipendenteLoggato(dipendenteLoggato);
      })
  }
  
/**
 * Funzione che in base al ruolo dell'utente seleziona un cantiere di default e
 * ottiene tutti i cantieri di appartenza dell'utente
 * 
 */
  function getCantieri() {
        if (sessionStorage.getItem('Role') == 'Admin')
        {
          cant.onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
              items.push( { ... doc.data(), id: doc.id } );
            });
            setCantieri('cantieri', items);
            ;
          });
        } else if (sessionStorage.getItem('Role') == 'Admin_Cantiere')
        {
          var email="";
          //IMPLEMENTAZIONE RUOLI MOMENTANEA
          if(userLogged.email=="m.goffi@arzagagolf.it"){
            email="admin.arzaga@arzagagolf.it";
          }else {
            email=userLogged.email;
          }
       
          refruolo.where('email', '==', email).onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
              items.push( { ... doc.data(), id: doc.id } );
            });
            setCantieri(items);
            ;
            if (sessionStorage.getItem('Cantiere') == undefined) {
              sessionStorage.setItem('Cantiere', items[0].Cantiere[0]);
              setNewCantiere(items[0].Cantiere[0]);
            }
          });
        } else if (sessionStorage.getItem('Role') == 'Admin_Zona')
        {
          refruolo.where('email', '==', userLogged.email).onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
              items.push( { ... doc.data(), id: doc.id } );
            });
            setCantieri(items);
            //;
            if (sessionStorage.getItem('Cantiere') == undefined) {
              sessionStorage.setItem('Cantiere', items[0].Zona[0]);
              setNewCantiere(items[0].Zona[0]);
            }
          });
        } else 
        {
          return
        }

  }

  /**
   * Funzione che cambia il cantiere selezionato nel momento in cui viene selezionato nella Select
   * @param {*} option Cantiere selezionato nella Select della SideBar
   */
  function handleCantiere (option) {
    setNewCantiere(option);
    sessionStorage.setItem('Cantiere', option);
    window.location.reload(false);
  }
  
  /**
   * Funzione che rileva tutte le notifiche dell'utente loggato
   */
  function getNotifiche() {
      notify.where("email", "==", userLogged.email).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setNotifiche(items);
      });
  }

  /**
   * Funzione che assegna il token (per firebase cloud messaging) all'utente loggato
   * @param {*} tokenuser 
   */
  function assignToken(tokenuser) {
    const items = [];
    tok.where("Email", "==", userLogged.email).where("Ruolo", "==", sessionStorage.getItem('Role')).onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        items.push( { ... doc.data(), id: doc.id } );
      });
      
      if (items.length == 0) {
        if (sessionStorage.getItem('Role') == 'Admin') {
          firebase.firestore().collection('Tokens').add({ Device: 'Desktop', Token: tokenuser, Ruolo: sessionStorage.getItem('Role'), Email: userLogged.email });
        } else {
          firebase.firestore().collection('Tokens').add({ Device: 'Desktop', Token: tokenuser, Ruolo: sessionStorage.getItem('Role'), Cantiere: sessionStorage.getItem('Cantiere'), Email: userLogged.email });
        }
      } else {
        if (sessionStorage.getItem('Role') == 'Admin') {
          firebase.firestore().collection('Tokens').doc(items[0].id).update({ Device: 'Desktop', Token: tokenuser, Ruolo: sessionStorage.getItem('Role'), Email: userLogged.email });
        } else {
          firebase.firestore().collection('Tokens').doc(items[0].id).update({ Device: 'Desktop', Token: tokenuser, Ruolo: sessionStorage.getItem('Role'), Cantiere: sessionStorage.getItem('Cantiere'), Email: userLogged.email });
        }
      }

    });
  }

  useEffect(() => {
    getLicenza();
    getDipendenti();
    if(sessionStorage.getItem('Page')!==undefined && sessionStorage.getItem('Page')!==null){
      setPage(sessionStorage.getItem('Page'))
      const ruolo=[];
      ruolo.push({Ruolo:sessionStorage.getItem('Role')}); //Non essendo il primo avvio leggo il ruolo da session storage
      setRuoli(ruolo);
    }else{  //Primo avvio  
      getRuoli();
      setPage("Home");
    }
    if(sessionStorage.getItem('clickNotify')!==undefined && sessionStorage.getItem("clickNotify")!==null){
      sessionStorage.removeItem("clickNotify");
      sessionStorage.setItem("tab1",1);
      setPage("Manutenzione");
    }
    if(sessionStorage.getItem("smallSidebar")!==null){
      if(sessionStorage.getItem("smallSidebar")==="true"){
        setSmallSideBar(true);
      }else{
        setSmallSideBar(false)
      }
    }
    
  }, []);

  /**
   * Ogni volta che viene selezionata una pagina diversa la salvo in session storage.
   * Permette di ritornare all'ultima pagina visitata nel caso in cui venga ricaricata la pagina
   */
  useEffect(() => {
    sessionStorage.setItem('Page',page);
  }, [page]);

  /**
   * Permette di verificare se il browser che esegue la web app supporta le notifiche push o no.
   * Se le supporta ottiene genera un token, altrimenti le blocca non causando crash
   * Richiede inoltre i permessi di ricevere notifiche all'utente.
   */
  useEffect(() => {
    getCantieri();
    if (!('Notification' in window)) {

    } else {

      const messaging = firebase.messaging(); 
      if (Notification.permission === "granted") {
        messaging.getToken({ vapidKey: 'BNg0wQp2Ba19brFLj_5FCU8Ewbg66PDiNgSolWnOJp9swriJmSsSgudCOw69q8RNmnyiJaHDyfZxy0nuYkSDaCo' }).then((token) =>{
          if (token) {

            sessionStorage.setItem('Token', token);
            assignToken(token);
          } else {

          }
        }).catch((err) => {

        });
      } else if (Notification.permission !== "denied") {

        Notification.requestPermission().then(function (permission) {

          if(permission==="granted"){
            messaging.getToken({ vapidKey: 'BNg0wQp2Ba19brFLj_5FCU8Ewbg66PDiNgSolWnOJp9swriJmSsSgudCOw69q8RNmnyiJaHDyfZxy0nuYkSDaCo' }).then((token) =>{
              if (token) {

                sessionStorage.setItem('Token', token);
                assignToken(token);
              } else {

              }
            }).catch((err) => {

            });
          }else{

          }
        })
    }
  }
    getNotifiche();
  }, [ruoli]);

  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  
    /**
     * Funzione che permette di sapere le dimensioni aggiornate dello schermo
     */
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }


  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);


  /**
   * Controllo in base a width e height l'orientamento dello schermo.
   * Per essere respondive blocco la web app in LandScape.
   */
  React.useEffect(()=>{
    if(width<1100){
      if(width<height){
        setLandScapeError(true);
      }else{
        setLandScapeError(false);
      }    
    }
  },[width,height])


  if (sessionStorage.getItem("firebase:authUser:AIzaSyDrje9pCvY8yV0DJ8fz3MsI05pYsS4Tafc:[DEFAULT]") == null) {
      return <Redirect to='/' />
  }


  function handleLogOut() {
    sessionStorage.clear();

    window.location.href = window.location.origin + "/";
  }

  const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyDrje9pCvY8yV0DJ8fz3MsI05pYsS4Tafc:[DEFAULT]"));

  /**
   * Funzione che controlla le licenze dell'ambiente e le passa ai componenti interessati
   * Permetterà di nascondere/visualizzare i contenuti aggiuntivi
   */
  function getLicenza(){
    licenze.onSnapshot((querySnapshot)=>{
      var licenzaDashboardAttApp;
      var licenzaDashboardMacApp;
      querySnapshot.forEach((doc)=>{
        licenzaDashboardAttApp=doc.data().DashboardActivity;
        licenzaDashboardMacApp=doc.data().DashboardMac;
      })
      setLicenzaDashboardAtt(licenzaDashboardAttApp);
      setLicenzaDashboardMac(licenzaDashboardMacApp);
    })
  }

  /**
   * Componente che implementa la SideBar
   * @param {*} props 
   * @returns 
   */
  function SideBarComp(props){
    return(
        <Sidebar
              height="97vh"
              width= {!smallSideBar?"250px":"100px"}
              responsive={true}
              background="#FFFFFF"
              header={ 
             
             <Box align="center" >
                 {
                  !smallSideBar?
                  <>
                  <Box height="20px">
                    {ruoli[0] !== undefined && cantieri[0] !== undefined ?
                    (ruoli[0].Ruolo == 'Admin_Cantiere' ?
                    <Select
                        options={cantieri[0].Cantiere.map(cantiere => (cantiere))}
                        value={newCantiere}
                        onChange={({ option }) => handleCantiere(option)}
                      />
                    :
                    (ruoli[0].Ruolo == 'Admin_Zona' ?
                    <Select
                        options={cantieri[0].Zona.map(cantiere => (cantiere))}
                        value={newCantiere}
                        onChange={({ option }) => handleCantiere(option)}
                      />
                      :
                      null
                    )
                    )
                    :
                    null
                    }
                  </Box>
                  <Box height="small" pad="small">
                    <Image fit="contain" src="./images/Logo1.png" />
                  </Box>
                  </>
                  :
                  null
                 }
                 <Box align="center" direction="column" gap="7px" >
                  <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:page=="Home"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() =>{setPage("Home")}}>
                      <Box pad="10px" direction="row" align="center" gap="medium">
                          <Home color={page!="Home"?'black':'#FFFFFF'} />
                          {
                            !smallSideBar?
                            <Text size="xsmall" color={page!="Home"?'black':'#FFFFFF'}>Home</Text>
                            :
                            null
                          }
                       
                      </Box>
                  </Button>
                  {ruoli[0] !== undefined ?
                     ( ruoli[0].Ruolo == 'Admin') ||  (ruoli[0].Ruolo == 'Admin_Cantiere' && dipendenteLoggato && dipendenteLoggato.ModificaAnagrafiche) ||  (ruoli[0].Ruolo == 'Admin_Zona' && dipendenteLoggato && dipendenteLoggato.ModificaAnagrafiche) ?
                  <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:page=="Anagrafiche"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() => {setPage("Anagrafiche")}}>
                      <Box pad="10px" direction="row" align="center" gap="medium">
                          <UserWorker color={page!="Anagrafiche"?'black':'#FFFFFF'} />
                          {
                            !smallSideBar?
                          <Text size="xsmall" color={page!="Anagrafiche"?'black':'#FFFFFF'}>Anagrafiche</Text>
                          :
                          null
                          }
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  {ruoli[0] !== undefined ?
                      ruoli[0].Ruolo == 'Admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona' ?
                  <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:page=="Attività"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Attività")}}> 
                      <Box pad="10px" direction="row" align="center" gap="medium">
                          <List color={page!="Attività"?'black':'#FFFFFF'}/>
                          {
                            !smallSideBar?
                            <Text size="xsmall" color={page!="Attività"?'black':'#FFFFFF'}>Attività</Text>
                            :
                            null
                          }
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  {ruoli[0] !== undefined ?
                          ruoli[0].Ruolo == 'Admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona' ?
                  
                  <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:page=="Geo ref."?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Geo ref.")}}>
                      <Box pad="10px" direction="row" align="center" gap="medium">
                          <MapLocation color={page!="Geo ref."?'black':'#FFFFFF'} />
                          {
                            !smallSideBar?
                              <Text size="xsmall" color={page!="Geo ref."?'black':'#FFFFFF'}>Mappa</Text>
                            :
                            null
                          }
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  {
                  ruoli[0] !== undefined ?
                  ruoli[0].Ruolo == 'Admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona' ||  ruoli[0].Ruolo == 'Meccanico' ?
                  <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:page=="Manutenzione"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Manutenzione")}}>
                      <Box pad="10px" direction="row" align="center" gap="medium">
                          <Configure color={page!="Manutenzione"?'black':'#FFFFFF'} />
                          {
                            !smallSideBar?
                            <Text size="xsmall"  color={page!="Manutenzione"?'black':'#FFFFFF'}>Macchinari</Text>
                            :
                            null
                          }
                          
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  {
                  ruoli[0] !== undefined ?
                  ruoli[0].Ruolo == 'Admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona'  ?
                  
                  <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:page=="Analitiche"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Analitiche")}}>
                      <Box pad="10px" direction="row" align="center" gap="medium">
                          <Analytics color={page!="Analitiche"?'black':'#FFFFFF'}/>
                          {
                            !smallSideBar?
                            <Text size="xsmall" color={page!="Analitiche"?'black':'#FFFFFF'}>Analitiche</Text>
                            :
                            null
                          }
                         
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:page=="Notifiche"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Notifiche")}}
                      badge={{
                        background: notifiche.length == 0 ? "none" : "status-critical",
                        value: notifiche.length == 0 ? false : notifiche.length,
                        max: 9,
                        size: 'medium'
                      }}
                    >
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <NotificationApp color={page!="Notifiche"?'black':'#FFFFFF'} />
                          {
                            !smallSideBar?
                            <Text size="xsmall" color={page!="Notifiche"?'black':'#FFFFFF'} >Notifiche</Text>
                            :
                            null
                          }
                        
                      </Box>
                  </Button>
                  
              </Box>    
              </Box>
              
              }   
              
              footer=
                {!smallSideBar?
                  <Button style={{width:"55px",background:'white',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() => {setSmallSideBar(true);sessionStorage.setItem("smallSidebar",true)}}>
                    <Box pad="small" direction="row" align="center" gap="medium">
                        <ArrowCircleLeftIcon color='success' fontSize="large"/>
                    </Box>
                 </Button>
                :
              <Button style={{width:"55px",background:'white',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() => {setSmallSideBar(false);sessionStorage.setItem("smallSidebar",false)}}>
                    <Box pad="small" direction="row" align="center" gap="medium">                     
                        <ArrowCircleRightIcon color='success' fontSize="large"/>
                    </Box>
                 </Button>
                }
              >
              
          </Sidebar>
    )
  }

  /*
    Componente che implementa la AideBar per dispositivi Mobile
  */
  function SideBarMobile(props){
    return(
        <Box
              height="97vh"
              width="100%"
              background="#FFFFFF"
              overflow="auto"
              style={{overflowX: 'hidden'}}
              pad="small"
              >
              <Box align="center" direction="column" gap="small" margin="medium" width="100%">
                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Home"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() =>{setPage("Home");setShowSideBarMob(false)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <Home color={page!="Home"?'black':'#FFFFFF'} />
                          <Text size="small" color={page!="Home"?'black':'#FFFFFF'}>Home</Text>
                      </Box>
                  </Button>
                  {ruoli[0] !== undefined ?
                    ( ruoli[0].Ruolo == 'Admin') ||  ruoli[0].Ruolo == 'Admin_Cantiere' && dipendenteLoggato && dipendenteLoggato.ModificaAnagrafiche ||  ruoli[0].Ruolo == 'Admin_Zona' && dipendenteLoggato && dipendenteLoggato.ModificaAnagrafiche ?
                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Anagrafiche"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() => {setPage("Anagrafiche");setShowSideBarMob(false)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <UserWorker color={page!="Anagrafiche"?'black':'#FFFFFF'} />
                          <Text size="small" color={page!="Anagrafiche"?'black':'#FFFFFF'}>Anagrafiche</Text>
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  {ruoli[0] !== undefined ?
                      ruoli[0].Ruolo == 'Admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona' ?
                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Attività"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Attività");setShowSideBarMob(false)}}> 
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <List color={page!="Attività"?'black':'#FFFFFF'}/>
                          <Text size="small" color={page!="Attività"?'black':'#FFFFFF'}>Attività</Text>
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  {ruoli[0] !== undefined ?
                          ruoli[0].Ruolo == 'Admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona' ?
                  
                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Geo ref."?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Geo ref.");setShowSideBarMob(false)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <MapLocation color={page!="Geo ref."?'black':'#FFFFFF'} />
                          <Text size="small" color={page!="Geo ref."?'black':'#FFFFFF'}>Mappa</Text>
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  {
                  ruoli[0] !== undefined ?
                  ruoli[0].Ruolo == 'Admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona' ||  ruoli[0].Ruolo == 'Meccanico' ?
                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Manutenzione"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Manutenzione");setShowSideBarMob(false)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <Configure color={page!="Manutenzione"?'black':'#FFFFFF'} />
                          <Text size="small"  color={page!="Manutenzione"?'black':'#FFFFFF'}>Manutenzione</Text>
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  {
                  ruoli[0] !== undefined ?
                  ruoli[0].Ruolo == 'Admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona' ?
                  
                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Analitiche"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Analitiche");setShowSideBarMob(false)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <Analytics color={page!="Analitiche"?'black':'#FFFFFF'}/>
                          <Text size="small" color={page!="Analitiche"?'black':'#FFFFFF'}>Analitiche</Text>
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Notifiche"?'#006837':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Notifiche");setShowSideBarMob(false)}}
                   
                    >
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <NotificationApp color={page!="Notifiche"?'black':'#FFFFFF'} />
                          <Text size="small" color={page!="Notifiche"?'black':'#FFFFFF'} >Notifiche</Text>
                      </Box>
                  </Button>
              </Box>
          </Box>
    )
  }

  return (
    <Grommet theme={theme} themeMode="dark" className="font-link">
      <ResponsiveContext.Consumer>
        {size => (
         <Box fill height="97vh">
          {width>=1100?
            <Box direction="row">
              <SideBarComp/>
                <Box width="100%">
                {page=="Anagrafiche"? 
                      <Anagrafiche />
                  :
                  page=="Attività"?
                  <AttivitaDet1/>
                  :
                  page=="Geo ref."?
                  <BucheDet/>
                  :
                  page=="Manutenzione"?
                  <Manutenzione licenzaDashboardMac={licenzaDashboardMac}/>
                  :
                  page=="Analitiche"?
                  <Dashboard licenzaDashboardAtt={licenzaDashboardAtt} licenzaDashboardMac={licenzaDashboardMac}/>
                  :
                  page=="Notifiche"?
                  <Notifiche/>
                  :
                  <Box height="97vh" direction="column" background="#0350281A">
                    <Box margin="2%" alignSelf="end">
                      <AccountBox logout={handleLogOut} name={nomeDip}/>
                    </Box>
                    <Box alignSelf="center" overflow="auto" style={{overflowX:"hidden"}}>
                    <Grid
                rows={['auto', 'auto']}
                columns={['1fr', '1fr', '1fr']}
                gap="xlarge"
                pad="large">
                   {/*CHANGE PER PROFILAZIONE ACCESSI*/}
                {ruoli[0] !== undefined ?
                  (ruoli[0].Ruolo == 'Admin' || ruoli[0].Ruolo == 'Admin_Cantiere' || ruoli[0].Ruolo == 'Admin_Zona' ?
                  <>
                    <Card background="accent-1">
                      <CardHeader pad="medium" alignSelf="center"><Heading level="3" margin="xsmall">Attività</Heading></CardHeader>
                        <CardBody pad="medium" alignSelf="center"><Button icon=<Task style={{width:'100px',height:'100px'}} color="brand" /> onClick={() => setPage("Attività")}/></CardBody>
                        <CardFooter pad={{horizontal: "small"}} background="brad">              
                        </CardFooter>
                    </Card>
                  </>
                  :
                    null
                  )
                :
                  null
                }
                 {/*CHANGE PER PROFILAZIONE ACCESSI*/}
                 {ruoli[0] !== undefined ?
                  (ruoli[0].Ruolo == 'Admin' || ruoli[0].Ruolo == 'Admin_Cantiere' || ruoli[0].Ruolo == 'Admin_Zona' ?
                  <>
                    <Card background="accent-1">
                      <CardHeader pad="medium" alignSelf="center"><Heading level="3" margin="xsmall">Analitiche</Heading></CardHeader>
                        <CardBody pad="medium" alignSelf="center"><Button icon=<Analytics style={{width:'100px',height:'100px'}} color="brand"/> onClick={() => setPage("Analitiche")} /></CardBody>
                        <CardFooter pad={{horizontal: "small"}} background="brand">         
                        </CardFooter>
                    </Card>
                  </>
                  :
                    null
                  )
                :
                  null
               } 
               {/*CHANGE PER PROFILAZIONE ACCESSI*/}
               {ruoli[0] !== undefined ?
                  (ruoli[0].Ruolo == 'Admin' || ruoli[0].Ruolo == 'Admin_Cantiere' || ruoli[0].Ruolo == 'Admin_Zona' || ruoli[0].Ruolo == 'Meccanico' ?
                  <>
                    <Card background="accent-1"> 
                      <CardHeader pad="medium" alignSelf="center"><Heading level="3" margin="xsmall">Manutenzione Macchinari</Heading></CardHeader>
                        <CardBody pad="medium" alignSelf="center"><Button icon=<Configure style={{width:'100px',height:'100px'}} color="brand"/> onClick={() =>setPage("Manutenzione")}  /></CardBody>
                        <CardFooter pad={{horizontal: "small"}} background="brand">         
                        </CardFooter>
                    </Card>
                  </>
                  :
                    null
                  )
                :
                  null
                }
                 {/*CHANGE PER PROFILAZIONE ACCESSI*/}
                 {ruoli[0] !== undefined ?
                  (ruoli[0].Ruolo == 'Admin' || ruoli[0].Ruolo == 'Admin_Cantiere' || ruoli[0].Ruolo == 'Admin_Zona' ?
                  <>
                    <Card background="accent-1"> 
                      <CardHeader pad="medium" alignSelf="center"><Heading level="3" margin="xsmall">Mappa</Heading></CardHeader>
                        <CardBody pad="medium" alignSelf="center"><Button icon=<MapLocation style={{width:'100px',height:'100px'}} color="brand" /> onClick={() => setPage("Geo ref.")} /></CardBody>
                        <CardFooter pad={{horizontal: "small"}} background="brand">         
                        </CardFooter>
                    </Card>
                  </>
                  :
                    null
                  )
                :
                  null
                }
                {/*CHANGE PER PROFILAZIONE ACCESSI*/}
                {ruoli[0] !== undefined ?
                  (ruoli[0].Ruolo == 'Admin' || ruoli[0].Ruolo == 'Admin_Cantiere' || ruoli[0].Ruolo == 'Admin_Zona'?
                  <>
                    <Card background="accent-1">
                      <CardHeader alignSelf="center" pad="medium"><Heading level="3" margin="xsmall"> Anagrafiche</Heading></CardHeader>
                      <CardBody pad="medium" alignSelf="center"><Button icon=<UserWorker style={{width:'100px',height:'100px'}} color="brand" /> onClick={() =>setPage("Anagrafiche")}/></CardBody>
                      <CardFooter pad={{horizontal: "small"}} background="brand"></CardFooter>
                    </Card>
                  </>
                  :
                    null
                  )
                :
                  null
                }
                 {/*CHANGE PER PROFILAZIONE ACCESSI*/}
                 {ruoli[0] !== undefined ?
                  (ruoli[0].Ruolo == 'Admin' || ruoli[0].Ruolo == 'Admin_Cantiere' || ruoli[0].Ruolo == 'Admin_Zona'?
                  <>
                    <Card background="accent-1">
                      <CardHeader alignSelf="center" pad="medium"><Heading level="3" margin="xsmall"> Notifiche</Heading></CardHeader>
                      <CardBody pad="medium" alignSelf="center"><Button icon=<NotificationApp style={{width:'100px',height:'100px'}} color="brand" /> onClick={() => setPage("Notifiche")}/></CardBody>
                      <CardFooter pad={{horizontal: "small"}} background="brand"></CardFooter>
                    </Card>
                  </>
                  :
                    null
                  )
                :
                  null
                }
               
               
                
               
              </Grid>
                    </Box>
                  </Box>
                }
               </Box>
            </Box>
            :
            !landScapeError?
            <Box direction="column">
               <AppBar position="static" style={{ background: '#006837' }}>
                <Toolbar>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Taskonnect
                  </Typography>
                  <Box direction="row" align="end" gap="small">
                    {ruoli[0] !== undefined && cantieri[0] !== undefined ?
                      (ruoli[0].Ruolo == 'Admin_Cantiere' ?
                      <Select
                          options={cantieri[0].Cantiere.map(cantiere => (cantiere))}
                          value={newCantiere}
                          onChange={({ option }) => handleCantiere(option)}
                        />
                      :
                      (ruoli[0].Ruolo == 'Admin_Zona' ?
                      <Select
                          options={cantieri[0].Zona.map(cantiere => (cantiere))}
                          value={newCantiere}
                          onChange={({ option }) => handleCantiere(option)}
                        />
                        :
                        null
                      )
                      )
                      :
                      null
                      }    
                       <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"    
                    onClick={() => {
                     if(!showSideBarMob){
                      setShowSideBarMob(true);
                     }else{
                       setShowSideBarMob(false);
                     }      
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  </Box>
                 
                </Toolbar>
              </AppBar>
                {!showSideBarMob?
                <Box width="100%">
                {page=="Anagrafiche"? 
                      <Anagrafiche />
                  :
                  page=="Attività"?
                  <AttivitaDet1/>
                  :
                  page=="Geo ref."?
                  <BucheDet/>
                  :
                  page=="Manutenzione"?
                  <Manutenzione licenzaDashboardMac={licenzaDashboardMac}/>
                  :
                  page=="Analitiche"?
                  <Dashboard licenzaDashboardAtt={licenzaDashboardAtt} licenzaDashboardMac={licenzaDashboardMac}/>
                  :
                  page=="Notifiche"?
                  <Notifiche/>
                  :
                  <Box height="97vh" direction="column" background="#0350281A">
                    <Box margin="2%" alignSelf="end">
                      <AccountBox logout={handleLogOut} name={nomeDip}/>
                    </Box>
                    <Box alignSelf="center" overflow="auto" style={{overflowX:"hidden"}} >
                    <Grid
                rows={['auto', 'auto']}
                columns={['1fr', '1fr', '1fr']}
                gap="xlarge"
                pad="large">
                   {/*CHANGE PER PROFILAZIONE ACCESSI*/}
                {ruoli[0] !== undefined ?
                  (ruoli[0].Ruolo == 'Admin' || ruoli[0].Ruolo == 'Admin_Cantiere' || ruoli[0].Ruolo == 'Admin_Zona' ?
                  <>
                    <Card background="accent-1">
                      <CardHeader pad="medium" alignSelf="center"><Heading level="3" margin="xsmall">Attività</Heading></CardHeader>
                        <CardBody pad="medium" alignSelf="center"><Button icon=<Task style={{width:'100px',height:'100px'}} color="brand" /> onClick={() => setPage("Attività")}/></CardBody>
                        <CardFooter pad={{horizontal: "small"}} background="brad">              
                        </CardFooter>
                    </Card>
                  </>
                  :
                    null
                  )
                :
                  null
                }
                 {/*CHANGE PER PROFILAZIONE ACCESSI*/}
                 {ruoli[0] !== undefined ?
                  (ruoli[0].Ruolo == 'Admin' || ruoli[0].Ruolo == 'Admin_Cantiere' || ruoli[0].Ruolo == 'Admin_Zona' ?
                  <>
                    <Card background="accent-1">
                      <CardHeader pad="medium" alignSelf="center"><Heading level="3" margin="xsmall">Analitiche</Heading></CardHeader>
                        <CardBody pad="medium" alignSelf="center"><Button icon=<Analytics style={{width:'100px',height:'100px'}} color="brand"/> onClick={() => setPage("Analitiche")} /></CardBody>
                        <CardFooter pad={{horizontal: "small"}} background="brand">         
                        </CardFooter>
                    </Card>
                  </>
                  :
                    null
                  )
                :
                  null
               } 
               {/*CHANGE PER PROFILAZIONE ACCESSI*/}
               {ruoli[0] !== undefined ?
                  (ruoli[0].Ruolo == 'Admin' || ruoli[0].Ruolo == 'Admin_Cantiere' || ruoli[0].Ruolo == 'Admin_Zona' || ruoli[0].Ruolo == 'Meccanico' ?
                  <>
                    <Card background="accent-1"> 
                      <CardHeader pad="medium" alignSelf="center"><Heading level="3" margin="xsmall">Manutenzione Macchinari</Heading></CardHeader>
                        <CardBody pad="medium" alignSelf="center"><Button icon=<Configure style={{width:'100px',height:'100px'}} color="brand"/> onClick={() =>setPage("Manutenzione")}  /></CardBody>
                        <CardFooter pad={{horizontal: "small"}} background="brand">         
                        </CardFooter>
                    </Card>
                  </>
                  :
                    null
                  )
                :
                  null
                }
                 {/*CHANGE PER PROFILAZIONE ACCESSI*/}
                 {ruoli[0] !== undefined ?
                  (ruoli[0].Ruolo == 'Admin' || ruoli[0].Ruolo == 'Admin_Cantiere' || ruoli[0].Ruolo == 'Admin_Zona' ?
                  <>
                    <Card background="accent-1"> 
                      <CardHeader pad="medium" alignSelf="center"><Heading level="3" margin="xsmall">Mappa</Heading></CardHeader>
                        <CardBody pad="medium" alignSelf="center"><Button icon=<MapLocation style={{width:'100px',height:'100px'}} color="brand" /> onClick={() => setPage("Geo ref.")} /></CardBody>
                        <CardFooter pad={{horizontal: "small"}} background="brand">         
                        </CardFooter>
                    </Card>
                  </>
                  :
                    null
                  )
                :
                  null
                }
                {/*CHANGE PER PROFILAZIONE ACCESSI*/}
                {ruoli[0] !== undefined ?
                  (ruoli[0].Ruolo == 'Admin' || ruoli[0].Ruolo == 'Admin_Cantiere' || ruoli[0].Ruolo == 'Admin_Zona'?
                  <>
                    <Card background="accent-1">
                      <CardHeader alignSelf="center" pad="medium"><Heading level="3" margin="xsmall"> Anagrafiche</Heading></CardHeader>
                      <CardBody pad="medium" alignSelf="center"><Button icon=<UserWorker style={{width:'100px',height:'100px'}} color="brand" /> onClick={() =>setPage("Anagrafiche")}/></CardBody>
                      <CardFooter pad={{horizontal: "small"}} background="brand"></CardFooter>
                    </Card>
                  </>
                  :
                    null
                  )
                :
                  null
                }
                 {/*CHANGE PER PROFILAZIONE ACCESSI*/}
                 {ruoli[0] !== undefined ?
                  (ruoli[0].Ruolo == 'Admin' || ruoli[0].Ruolo == 'Admin_Cantiere' || ruoli[0].Ruolo == 'Admin_Zona'?
                  <>
                    <Card background="accent-1">
                      <CardHeader alignSelf="center" pad="medium"><Heading level="3" margin="xsmall"> Notifiche</Heading></CardHeader>
                      <CardBody pad="medium" alignSelf="center"><Button icon=<NotificationApp style={{width:'100px',height:'100px'}} color="brand" /> onClick={() => setPage("Notifiche")}/></CardBody>
                      <CardFooter pad={{horizontal: "small"}} background="brand"></CardFooter>
                    </Card>
                  </>
                  :
                    null
                  )
                :
                  null
                }
               
               
                
               
              </Grid>
                    </Box>
                  </Box>
                }           
                </Box>
                :
                <SideBarMobile/>          
                }
              
            </Box>  
            :
            <Box direction="column">
            <AppBar position="static" style={{ background: '#006837' }}>
             <Toolbar>
               <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                 Taskonnect
               </Typography>
               <Box direction="row" align="end" gap="small">
                 {ruoli[0] !== undefined && cantieri[0] !== undefined ?
                   (ruoli[0].Ruolo == 'Admin_Cantiere' ?
                   <Select
                       options={cantieri[0].Cantiere.map(cantiere => (cantiere))}
                       value={newCantiere}
                       onChange={({ option }) => handleCantiere(option)}
                     />
                   :
                   (ruoli[0].Ruolo == 'Admin_Zona' ?
                   <Select
                       options={cantieri[0].Zona.map(cantiere => (cantiere))}
                       value={newCantiere}
                       onChange={({ option }) => handleCantiere(option)}
                     />
                     :
                     null
                   )
                   )
                   :
                   null
                   }    
                    <IconButton
                 size="large"
                 edge="start"
                 color="inherit"
                 aria-label="menu"
                 onClick={() => {
                  if(!showSideBarMob){
                   setShowSideBarMob(true);
                  }else{
                    setShowSideBarMob(false);
                  }      
                 }}
               >
                 <MenuIcon />
               </IconButton>
               </Box>
              
             </Toolbar>
           </AppBar>
            <Box width="100%" height="100%" align="center" alignSelf="center">
              <Text margin="small" color="#04671E" size="large" weight="bold">GIRA LO SCHERMO PER UTILIZZARE TASKONNECT</Text>
            </Box>
         </Box>  
            }
          </Box>)}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;

