import React, { useState, useEffect, useRef } from "react";
import { Box, Grommet,DateInput, Text, Card, CardHeader, CardBody,Image, Select, CardFooter, Clock, TextInput, Button, Anchor, CheckBoxGroup, WorldMap, DataChart, DataTable, Meter, Heading, Collapsible, Layer, ResponsiveContext, DropButton, Notification as NotificationApp ,Tabs,Tab,Table,TableHeader,TableCell,TableRow,TableBody,CheckBox} from 'grommet';
import { FormClose, Notification,Calendar, Home, CircleInformation, Add, Favorite, ShareOption, LinkPrevious, UserWorker, MapLocation, Analytics, Task, Configure,Filter } from "grommet-icons";
import Notifications from "./Notifications";
import firebase from '../firebase';
import  { Redirect } from 'react-router-dom';
import moment from "moment";

const theme = {
  global: {
    colors: {
      brand: '#006837',
      'accent-1': 'white',
      'focus': '#006837',
      "my-text-color": "",
      background: '#0350281A',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
    menu:{
      icons:{
        color:'white',
        },
    },
  },
};

const App = (props) => {
  
  
  const dipendenti=firebase.firestore().collection('Dipendenti');  
  const [tab,setTab]=React.useState(0);
  const [encodedParams,setEncodedParams]=React.useState(null);

    /*CHANGE PER IMPLEMENTAZIONE RUOLI*/
  const [ruoli, setRuoli] = useState([]);
  const role = firebase.firestore().collection('Ruoli');
  /*END CHANGE IMPLEMENTAZIONE RUOLI*/

  /*CHANGE PER IMPLEMENTAZIONE RUOLI*/

  /*
    Funzione che in base all'utente loggato ottiene l'array di cantieri da passare all'iframe
    per dataStudio
  */
  function getCantieri(){
    const items=[];
    items.push(sessionStorage.getItem("Cantiere"));
    console.log("ITEMS",items);
    var params = {
      "ds8.cantiereurl2": items,
      "ds41.cantiereurldipendenti":items
    };
    var paramsAsString = JSON.stringify(params);
    var encodedParamsApp = encodeURIComponent(paramsAsString)
    setEncodedParams(encodedParamsApp);
  }


  /**
   * Funzione che rileva il ruolo dell'utente loggato
   */
  function getRuoli() {
      role.where('Utente', '==', userLogged.email).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setRuoli(items);
        
      });
  }

  

  useEffect(() => {
    getRuoli();
    getCantieri();
  }, []);


  if (sessionStorage.getItem("firebase:authUser:AIzaSyDrje9pCvY8yV0DJ8fz3MsI05pYsS4Tafc:[DEFAULT]") == null) {
    return <Redirect to='/'  />
  }

  const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyDrje9pCvY8yV0DJ8fz3MsI05pYsS4Tafc:[DEFAULT]"));

  
  //Funzione per gestire i tab
  const onActive = (nextIndex) =>{setTab(nextIndex)}

  return (
    <Grommet theme={theme} themeMode="dark" className="font-link">
      <ResponsiveContext.Consumer>
        {size => (
        <Box fill >
           <Box direction="row">
              <Box width="100%"  height="97vh" overflow="auto">
                <Tabs alignControls="start" onActive={onActive}>
                {props.licenzaDashboardAtt?
                  <Tab title="Attività">
                    <Box pad="1%" overflow="auto">
                      {encodedParams!==null?
                        <iframe frameBorder="0" style={{width:"100%", height:"800px"}} src={"https://lookerstudio.google.com/embed/reporting/273328fc-e49c-4e06-aba9-d40ed572612e/page/ebpnC?params=" + encodedParams} ></iframe>
                        :
                        null
                        }
                      </Box>                    
                  </Tab> 
                :
                null}
                 {props.licenzaDashboardAtt  && userLogged.email!=="marilena.l@arzagagolf.it"?
                  <Tab title="Costi">
                    <Box pad="1%" overflow="auto">
                      {encodedParams!==null?
                        <iframe frameBorder="0" style={{width:"100%", height:"800px"}} src={"https://lookerstudio.google.com/embed/reporting/9ac842ee-6628-4e8f-89ee-d83f761be75d/page/p_0y0vjolysc?params="+encodedParams} ></iframe>
                        :
                        null
                        }
                      </Box>                    
                  </Tab> 
                :
                null}
                {
                  !props.licenzaDashboardAtt?
                    <Text align="center" color="#04671E" margin="small" style={{fontWeight:'bold'}} >Il tuo piano non prevede questo modulo</Text>
                  :
                  null
                }
                </Tabs>
              </Box>
          </Box>
            </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;
